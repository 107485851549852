import { Template } from '../../enums/TemplateTypes'
import SpeakerGroup from './speaker-group'

export default class SpeakersSection {
    template: Template
    speakerGroups: SpeakerGroup[]
    textColor?: string

    constructor(data: any, speakerGroups?: any) {
        this.template = data.template || Template.TEMPLATE1
        this.speakerGroups = speakerGroups
            ? speakerGroups.map((speakerGroup: any) => new SpeakerGroup(speakerGroup))
            : []
        this.textColor = data.textColor
    }
}
