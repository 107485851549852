export const CONTENTFUL_ASSET_FRAGMENT = `
  title
  url
  width
  height
`

export const IMAGE_FRAGMENT = `
  image {${CONTENTFUL_ASSET_FRAGMENT}}
  alignment
  mobileAlignment
  width
  maxWidth
  borderWidth
  borderColor
`

export const IMAGE_GROUP_FRAGMENT = `
  orientation
  noSpacing
  imagesCollection(limit: 12) {
    items {
      ${IMAGE_FRAGMENT}
    }
  }
`

export const LINK_FRAGMENT = `
  title
  link
  internalexternal
  button
  style
  customBackgroundColor
  textColor
  fontSize
  linkWidth: width
  widthUnit
`

export const LINK_GROUP_FRAGMENT = `
  orientation
  linksCollection(limit: 5) {
    items {
      ${LINK_FRAGMENT}
    }
  }
`

export const COPY_FRAGMENT = `
  copy
  fontSize
  mobileFontSize
  color
  alignment
  backgroundColor
`

export const TITLE_FRAGMENT = `
  title
  type
  alignment
  fontWeight
  textColor
  lineBottom
  linesLeftAndRight
  textStyle
  url
`

export const CUSTOM_CONTENT_FRAGMENT = `
  template
  data
`

export const SPONSOR = `
  title
  image {${IMAGE_FRAGMENT}}
  url
  primarySponsor
`

export const SPONSOR_GROUPS_FRAGMENT = `
  title
  groupTitle {${TITLE_FRAGMENT}}
  featuredGroup
  sponsorsCollection(limit: 20) {
    items {
      ${SPONSOR}
    }
  }
`

export const SPONSORS_SECTION_FRAGMENT = `
  title
  template
`

export const SPEAKER_FRAGMENT = `
  name
  title
  description
  image {${IMAGE_FRAGMENT}}
`

export const SPEAKER_GROUP_FRAGMENT = `
  title
  groupTitle {${TITLE_FRAGMENT}}
  guestSpeakersCollection(limit: 20) {
    items {
      ${SPEAKER_FRAGMENT}
    }
  }
`

export const SPEAKERS_SECTION_FRAGMENT = `
  template
  textColor
`

export const PRIZE_FRAGMENT = `
  title
  description
  image {${IMAGE_FRAGMENT}}
`

export const PRIZE_SECTION_FRAGMENT = `
  template
`

export const VIDEO_CHAT_SECTION_FRAGMENT = `
  title
`

export const VIDEO_EMBED_FRAGMENT = `
  copy
  link
  allow
  modal
  height
`

export const CONTENT_COLLECTION_FRAGMENT = `
  __typename
  ... on Title {${TITLE_FRAGMENT}}
  ... on Copy {${COPY_FRAGMENT}}
  ... on Link {${LINK_FRAGMENT}}
  ... on Image {${IMAGE_FRAGMENT}}
  ... on ImageGroup {${IMAGE_GROUP_FRAGMENT}}
  ... on LinkGroup {${LINK_GROUP_FRAGMENT}}
  ... on VideoEmbed {${VIDEO_EMBED_FRAGMENT}}
  ... on CustomContent {${CUSTOM_CONTENT_FRAGMENT}}
`
