export enum CustomContentTemplates {
    Table = 'Table',
    SimpleTable = 'Simple Table',
    List = 'List',
}

export enum Template {
    TEMPLATE1 = 'Template 1',
    TEMPLATE2 = 'Template 2',
    TEMPLATE3 = 'Template 3',
}
