import React from 'react'
import { connect } from 'react-redux'
import { selectWebsite } from '../../../redux/selectors/cms'
import ReactHtmlParser from 'react-html-parser'
import classList from './IFrameChat.module.scss'

interface IProps {
    websiteData?: any
}

const mapStateToProps: any = (state: any) => {
    return {
        websiteData: selectWebsite(state),
    }
}

class IFrameChatComponent extends React.Component<IProps> {
    render() {
        const {
            websiteData: {
                data: { videoChat },
            },
        } = this.props
        return videoChat.chatIFrame ? (
            <div className={classList['iframe-wrapper']}>
                {ReactHtmlParser(videoChat.chatIFrame)}
            </div>
        ) : null
    }
}

const IFrameChat = connect(mapStateToProps)(IFrameChatComponent)
export default IFrameChat
