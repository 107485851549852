import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import IframePlayer from '../../components/VideoPlayer/IframePlayer/IframePlayer'
import VideoTypes from '../../enums/VideoTypes'
import {
    selectEventEnded,
    selectEventStarted,
    selectIsPostShow,
    selectWebsite,
} from '../../redux/selectors/cms'
import classList from './VideoContainer.module.scss'
import classNames from 'classnames'
import { getCurrentViewersCount } from '../../providers/viewer-stats'
import { LiveEventPlayer } from '@liveevent/video-player'
import { IPropWithData } from '../../class/interfaces/redux'
import Website from '../../class/models/website'
import { ChatType } from '../../class/models/video-chat'

interface IStateProps {
    websiteData: IPropWithData<Website>
    isPostShow?: boolean
    eventStarted: boolean
    eventEnded: boolean
}

interface IState {
    viewPollingIntervalId?: number
    currentViewers: number
}

const mapStateToProps = (state: any): IStateProps => {
    return {
        websiteData: selectWebsite(state),
        isPostShow: selectIsPostShow(state),
        eventStarted: selectEventStarted(state),
        eventEnded: selectEventEnded(state),
    }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux

class VideoContainerComponent extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            viewPollingIntervalId: undefined,
            currentViewers: 0,
        }
    }

    componentDidMount() {
        // if (this.isEventRunning()) {
        //     this.fetchViewCount()
        //     this.startViewPolling()
        // }
    }

    componentDidUpdate(prevProps: any) {
        const { eventStarted: prevEventstarted } = prevProps
        const { eventStarted: currEventStarted } = this.props

        if (!prevEventstarted && currEventStarted) {
            // this.fetchViewCount()
            // this.startViewPolling()
        }
    }

    componentWillUnmount() {
        this.stopViewPolling()
    }

    startViewPolling = () => {
        const { viewPollingIntervalId } = this.state
        if (!viewPollingIntervalId) {
            const intervalId = window.setInterval(() => {
                if (this.isEventRunning()) {
                    this.fetchViewCount()
                } else {
                    this.stopViewPolling()
                }
            }, 5000)
            this.setState({ viewPollingIntervalId: intervalId })
        }
    }

    stopViewPolling = () => {
        const { viewPollingIntervalId } = this.state
        if (viewPollingIntervalId) {
            window.clearInterval(viewPollingIntervalId)
            this.setState({ viewPollingIntervalId: undefined })
        }
    }

    fetchViewCount = async () => {
        const {
            websiteData: {
                data: { videoChat },
            },
        } = this.props

        try {
            const response = await getCurrentViewersCount(videoChat!.channelId || '')
            this.setState({
                currentViewers: response.data.Count,
            })
        } catch (e) {
            console.error('Error getting view stats: ', e)
        }
    }

    isEventRunning = () => {
        const { eventStarted, eventEnded } = this.props
        return eventStarted && !eventEnded
    }

    render() {
        const {
            isPostShow,
            websiteData: {
                data: { videoChat },
            },
        } = this.props

        const usingCustomChat = videoChat && videoChat.chatType === ChatType.CUSTOM

        return !videoChat ? null : (
            <>
                <div
                    className={classNames(classList['video-container'], {
                        [classList['no-border-radius']]: !usingCustomChat,
                        [classList['post-show']]: isPostShow,
                        [classList['no-padding-bottom']]:
                            videoChat.typeOfVideoToShow === VideoTypes.STREAM,
                    })}
                >
                    {videoChat.typeOfVideoToShow === VideoTypes.IFRAME && videoChat.iframeUrl ? (
                        <IframePlayer />
                    ) : (
                        <div
                            className={classNames(classList['video-border-radius'], {
                                [classList['no-border-radius']]: !usingCustomChat,
                            })}
                        >
                            {/* TODO: Move streamUrl and videoPoster to player component. */}
                            <LiveEventPlayer
                                eventInstanceId={videoChat.eventInstanceId || ''}
                                streamUrl={videoChat.streamUrl || ''}
                                videoPoster={videoChat.videoPoster!.url || ''}
                            />
                        </div>
                    )}
                    {/* {channelId && this.isEventRunning() && (
                        <div className={classNames(classList['view-count'])}>
                            Live: {currentViewers}
                        </div>
                    )} */}
                </div>
            </>
        )
    }
}

const VideoContainer = connect(mapStateToProps)(VideoContainerComponent)
export default VideoContainer
