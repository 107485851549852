import { createSelector } from 'reselect'

import { toJSSelector } from './utils'

const selectCmsI = (state) => state.cms

export const selectWebsite = toJSSelector(
    createSelector([selectCmsI], (cms) => {
        return cms.get('website')
    })
)

export const selectIsPostShow = createSelector([selectCmsI], (cms) => cms.get('isPostShow'))

export const selectEventStarted = createSelector([selectCmsI], (cms) => cms.get('eventStarted'))

export const selectEventEnded = createSelector([selectCmsI], (cms) => cms.get('eventEnded'))

export const selectIsMobile = createSelector([selectCmsI], (cms) => cms.get('isMobile'))
