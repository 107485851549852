enum SocialMediaTypes {
    FACEBOOK = 'facebook',
    TWITTER = 'twitter',
    YOUTUBE = 'youtube',
    INSTAGRAM = 'instagram',
    PINTEREST = 'pinterest',
    LINKEDIN = 'linkedin',
}

export default SocialMediaTypes
