import User from '../services/user'
import Analytics from './analytics'
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import { RegisterWalkInMutation, VerifyRegisteredUserQuery } from '../API'
import { verifyRegisteredUser } from '../graphql/queries'
import { store } from '..'
import { registerWalkIn } from '../graphql/mutations'

export const loginUser = async (email: string, code: string) => {
    const storeState = store.getState()
    const web = storeState.cms.get('website').toJS()

    try {
        const response: GraphQLResult<VerifyRegisteredUserQuery> = (await API.graphql(
            graphqlOperation(verifyRegisteredUser, { email, code, cmsId: web.data.id })
        )) as GraphQLResult<VerifyRegisteredUserQuery>

        if (!response.data || !response.data.verifyRegisteredUser) {
            Analytics.track('User Not Found', { email })
            throw new Error('USER_NOT_FOUND')
        }

        Analytics.identify(email, {})

        return response.data.verifyRegisteredUser
    } catch (e) {
        console.error(e)
        throw new Error('Error logging in.')
    }
}

export const registerUser = async ({ email, firstName, lastName }: any) => {
    const storeState = store.getState()
    const web = storeState.cms.get('website').toJS()

    const response: GraphQLResult<RegisterWalkInMutation> = (await API.graphql(
        graphqlOperation(registerWalkIn, { firstName, lastName, email, cmsId: web.data.id })
    )) as GraphQLResult<RegisterWalkInMutation>

    if (response.data && response.data.registerWalkIn) {
        Analytics.identify(email, { email, firstName, lastName })
        return response.data.registerWalkIn
    } else {
        Analytics.track('Error Regsitering User', { email, firstName, lastName })
        throw new Error('Error registering user.')
    }
}

export const logoutUser = async () => {
    localStorage.removeItem(User.USER_KEY)
    return {}
}
