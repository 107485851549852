/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-west-2',
    aws_appsync_graphqlEndpoint:
        'https://dwynhp4hybffrcq5g5fbt2bi6a.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-a7toubdssbd6pbkwkplfqg2oie',
}

export default awsmobile
