import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import Copy from '../../class/models/copy'
import { selectIsMobile } from '../../redux/selectors/cms'
import classList from './CustomCopy.module.scss'
const htmlParser = require('react-markdown/plugins/html-parser')

interface ICopy {
    data: Copy
}

const CustomCopy = ({ data }: ICopy) => {
    const isMobile: any = useSelector(selectIsMobile)
    const styles: any = {
        textAlign: data.alignment,
    }

    if (data.fontSize) {
        styles.fontSize = `${data.fontSize}px`
    }

    if (isMobile && data.mobileFontSize) {
        styles.fontSize = `${data.mobileFontSize}px`
    }

    if (data.color) {
        styles.color = `${data.color}`
    }

    if (data.backgroundColor) {
        styles.backgroundColor = `${data.backgroundColor}`
    }

    const parser = htmlParser({
        isValidNode: (node: any) => node.type !== 'script',
        // processingInstructions: [/* ... */]
    })

    return (
        <div style={styles} className={classList['custom-copy']}>
            <ReactMarkdown source={data.copy} escapeHtml={false} astPlugins={[parser]} />
        </div>
    )
}

export default CustomCopy
