import ComponentsList from '../../enums/ComponentsList'
import Copy from './copy'
import CustomContent from './custom-content'
import Image from './image'
import ImageGroup from './image-group'
import ContentfulLink from './link'
import LinkGroup from './link-group'
import PrizeSection from './prize-section'
import SpeakersSection from './speakers-section'
import SponsorsSection from './sponsors-section'
import Title from './title'
import VideoChatSection from './video-chat-section'
import VideoEmbed from './video-embed'

export default class ContentCollectionItem {
    type: ComponentsList;
    data:
        | Title
        | Copy
        | ContentfulLink
        | Image
        | ImageGroup
        | SponsorsSection
        | SpeakersSection
        | PrizeSection
        | CustomContent
        | VideoChatSection
        | LinkGroup
        | VideoEmbed
        | undefined;

    constructor(
        data: any,
        sponsorGroupsData?: any,
        speakersData?: any,
        prizesData?: any,
        videoChatData?: any
    ) {
        this.type = data.__typename
        switch (data.__typename) {
            case 'Title':
                this.data = new Title(data)
                break
            case 'Copy':
                this.data = new Copy(data)
                break
            case 'Link':
                this.data = new ContentfulLink(data)
                break
            case 'Image':
                this.data = new Image(data)
                break
            case 'ImageGroup':
                this.data = new ImageGroup(data)
                break
            case 'CustomContent':
                this.data = new CustomContent(data)
                break
            case 'Sponsors':
                this.data = new SponsorsSection(data, sponsorGroupsData)
                break
            case 'GuestSpeakersSection':
                this.data = new SpeakersSection(data, speakersData)
                break
            case 'PrizeSection':
                this.data = new PrizeSection(data, prizesData)
                break
            case 'VideochatSection':
                this.data = new VideoChatSection(data, videoChatData)
                break
            case 'LinkGroup':
                this.data = new LinkGroup(data)
                break
            case 'VideoEmbed':
                this.data = new VideoEmbed(data)
                break
            default:
        }
    }
}
