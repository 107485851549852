import { Template } from '../../enums/TemplateTypes'
import ContentCollectionItem from './content-collection'
import Image from './image'

export default class HeroSectionModel {
    title: string
    image?: Image
    backgroundColor?: string
    overlayImage?: Image
    text: string
    content?: ContentCollectionItem[]
    template: Template
    maxHeight?: number
    minHeight?: number
    paddingTop?: number
    paddingBottom?: number

    constructor(rawData: any) {
        const data = rawData || {}
        this.title = data.title
        this.maxHeight = data.maxHeight
        this.minHeight = data.minHeight
        this.image = data.image ? new Image(data.image) : undefined
        this.backgroundColor = data.backgroundColor
        this.overlayImage = data.overlayImage ? new Image(data.overlayImage) : undefined
        this.text = data.text
        this.content =
            data.contentCollection && data.contentCollection.items
                ? data.contentCollection.items.map(
                      (contentCollectionItem: any) =>
                          new ContentCollectionItem(contentCollectionItem)
                  )
                : []
        this.template = data.template || Template.TEMPLATE1
        this.paddingTop = data.paddingTop
        this.paddingBottom = data.paddingBottom
    }
}
