import React from 'react'
import SpeakersSection from '../../class/models/speakers-section'
import SpeakersSectionTemplateOne from '../../components/SpeakersSection/SpeakersSectionTemplateOne/SpeakersSectionTemplateOne.component'
import SpeakersSectionTemplateTwo from '../../components/SpeakersSection/SpeakersSectionTemplateTwo/SpeakersSectionTemplateTwo.component'
import { Template } from '../../enums/TemplateTypes'

interface ISpeakersSection {
    data: SpeakersSection
}

const SpeakersSectionContainer = ({ data }: ISpeakersSection) => {
    const styles: any = {}
    if (data.textColor) {
        styles.color = data.textColor
    }
    return data.speakerGroups ? (
        <div className="speakers-groups-wrap" style={styles}>
            {data.template === Template.TEMPLATE1 && <SpeakersSectionTemplateOne data={data} />}
            {data.template === Template.TEMPLATE2 && <SpeakersSectionTemplateTwo data={data} />}
        </div>
    ) : null
}

export default SpeakersSectionContainer
