import { IPropWithData } from '../class/interfaces/redux'
import Website from '../class/models/website'
import { store } from '../index'
import Analytics from '../providers/analytics'
import { setUser } from '../redux/actions/auth'
import { getSubdomain } from './utils'

class UserClass {
    storeState: any
    USER_KEY = `${getSubdomain()}_user`

    initUser() {
        const user = localStorage.getItem(UserService.USER_KEY)
        if (user) {
            Analytics.track("User Retrieved From Session", { user });
            store.dispatch(setUser(JSON.parse(user)))
        } else {
            store.dispatch(setUser({}))
        }
    }

    isLoggedIn() {
        const storeState = store.getState()
        const userRaw = storeState.auth.get('user')

        if (userRaw) {
            const user = userRaw.toJS()
            return !!(user.firstName || user.lastName || user.email)
        } else {
            return false
        }
    }

    getUsername() {
        const storeState = store.getState()
        const user = storeState.auth.get('user').toJS()
        return `${user.firstName} ${user.lastName}`
    }

    /**
     * Get the rooms IDs for a user.
     * TODO: endOfShow logic will not be the same for all events.
     * @param isEndOfShow true/false whether this room is for end of show.
     * @returns
     */
    getRoomIds(isEndOfShow: boolean): string[] {
        const storeState = store.getState()
        const user = storeState.auth.get('user').toJS()

        if (isEndOfShow) {
            return []
        }

        return user && user.roomId ? user.roomId.split(',') : []
    }

    get firstName() {
        const storeState = store.getState()
        const user = storeState.auth.get('user').toJS()
        return user.firstName
    }

    getUserProperties() {
        const storeState = store.getState()
        const user = storeState.auth.get('user').toJS()

        return user
    }

    getEmail() {
        const storeState = store.getState()
        const user = storeState.auth.get('user').toJS()
        return user.email
    }

    /**
     * Builds the breakout room url for a user given the users params.
     * @returns url string
     */
    buildBreakoutLink(): string {
        const storeState = store.getState()
        const cmsRaw: IPropWithData<Website> = storeState.cms.get('website').toJS()
        const userProps = this.getUserProperties()

        let url = new URL(document.location.href)
        if (cmsRaw && cmsRaw.data.breakoutRoom) {
            url = new URL(`https://${cmsRaw.data.breakoutRoom.subdomain}.room.liveevent.space/join`)
            url.searchParams.set('user', userProps.userId)
            url.searchParams.set('room', userProps.roomId)
        }

        return url.href
    }
}

const UserService = new UserClass()

export default UserService
