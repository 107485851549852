import {createSelector} from 'reselect';

import {toJSSelector} from './utils';

const selectChatI = (state) => state.chat;

export const selectGetMessages = toJSSelector(
    createSelector(
        [selectChatI],
        (chat) => {
            return chat.get('messages')
        },
    ),
);
