import Image from './image'

export default class ImageGroup {
    orientation: string
    noSpacing: boolean
    images: Image[]

    constructor(rawData: any) {
        const data = rawData || {}
        this.orientation = data.orientation
        this.noSpacing = !!data.noSpacing
        this.images =
            data.imagesCollection && data.imagesCollection.items
                ? data.imagesCollection.items.map((image: Image) => new Image(image))
                : []
    }
}
