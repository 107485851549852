import React, { useState } from 'react'
import classList from './Iframe.module.scss'
import classNames from 'classnames'
import { Modal } from 'react-bootstrap'

interface IProps {
    src: string
    modal?: boolean
    allow?: string
    height?: number
}

const Iframe = ({ src, modal, allow, height }: IProps) => {
    const [showModal, setShowModal] = useState(false)

    const onClickVideo = () => {
        if (modal) {
            setShowModal(true)
        }
    }

    const onHide = () => {
        setShowModal(false)
    }

    const builtSrc = new URL(src)
    builtSrc.searchParams.append('autoplay', '1')
    const wrapperStyle: any = {}
    if (!!height) {
        wrapperStyle.height = `${height}px`
    }

    return (
        <>
            <div
                className={classNames(
                    { [classList['has-modal']]: modal, [classList['has-height']]: !!height },
                    classList['iframe-wrapper']
                )}
                onClick={onClickVideo}
                style={wrapperStyle}
            >
                <iframe src={src} title="Iframe" frameBorder="0" allow={allow} />
            </div>
            <Modal
                show={showModal}
                onHide={onHide}
                dialogClassName={classList['modal-dialog']}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className={classList['iframe-wrapper']}>
                        <iframe src={builtSrc.href} title="Iframe" frameBorder="0" allow={allow} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Iframe
