import React from 'react'
import Title from '../../class/models/title'
import ReactHtmlParser from 'react-html-parser'
import FontWeight from '../../enums/Title'
import styles from './CustomTitle.module.scss'
import classNames from 'classnames'
import { TextStyle } from '../../enums/Text'

const fontWeightMapping = {
    [FontWeight.Normal]: 400,
    [FontWeight.Medium]: 500,
    [FontWeight.Bold]: 700,
}

interface ICustomTitle {
    data: Title
}

const CustomTitle = ({ data }: ICustomTitle) => {
    const dynamicStyles: any = {
        textAlign: data.alignment,
        fontWeight: fontWeightMapping[data.fontWeight],
    }

    if (data.textColor) {
        dynamicStyles.color = data.textColor
    }

    switch (data.textStyle) {
        case TextStyle.LOWERCASE:
            dynamicStyles.textTransform = 'lowercase'
            break
        case TextStyle.TITLECASE:
            dynamicStyles.textTransform = 'capitalize'
            break
        case TextStyle.UPPERCASE:
        default:
            dynamicStyles.textTransform = 'uppercase'
    }

    const CustomTag: any = `${data.type}` || 'h2'

    const underlineStyles: any = {}
    const sideLinesStyles: any = {}

    if (data.textColor) {
        underlineStyles.backgroundColor = data.textColor
        sideLinesStyles.borderColor = data.textColor
    }

    const titleContent = !!data.url ? (
        <a href={data.url} target="_blank" rel="noreferrer" style={dynamicStyles}>
            {ReactHtmlParser(data.title)}
        </a>
    ) : (
        ReactHtmlParser(data.title)
    )

    const titleSeparator = <div className={styles['title-separator']} style={sideLinesStyles}></div>

    return data.title ? (
        <div
            className={classNames(styles['title-wrapper'], {
                [styles['with-underline']]: data.lineBottom,
            })}
        >
            <div
                className={classNames({
                    'd-flex align-items-center justify-content-center': data.linesLeftAndRight,
                })}
            >
                {data.linesLeftAndRight && titleSeparator}
                <CustomTag
                    className={classNames(styles.title, {
                        [styles['side-lines']]: data.linesLeftAndRight,
                        'px-5 mb-0': data.linesLeftAndRight,
                    })}
                    style={dynamicStyles}
                >
                    {titleContent}
                </CustomTag>
                {data.linesLeftAndRight && titleSeparator}
            </div>
            {data.lineBottom && (
                <div className={styles['title-underline']} style={underlineStyles} />
            )}
        </div>
    ) : null
}

export default CustomTitle
