import { handleActions } from 'redux-actions'
import * as Immutable from 'immutable'
import { defaultFieldWithData, defaultReducerWithData } from './utils'

const initialState = Immutable.fromJS({
    website: defaultFieldWithData,
    isPostShow: false,
    eventStarted: false,
    eventEnded: false,
    isMobile: false,
})

export default handleActions(
    {
        CMS: {
            FETCH_WEBSITE: defaultReducerWithData('website'),
            SET_IS_POST_SHOW: (state, { payload }) => {
                return state.set('isPostShow', payload)
            },
            SET_EVENT_STARTED: (state, { payload }) => {
                return state.set('eventStarted', payload)
            },
            SET_EVENT_ENDED: (state, { payload }) => {
                return state.set('eventEnded', payload)
            },
            SET_IS_MOBILE: (state, { payload }) => {
                return state.set('isMobile', payload)
            },
            CLEAR_ERRORS: (state) => state.update('website', (data) => data.set('error', null)),
        },
    },
    initialState
)
