import * as Immutable from 'immutable'

export const defaultFieldWithData = {
    fetched: false,
    fetching: false,
    data: null,
    error: null,
}

export const defaultFieldWithoutData = {
    fetched: false,
    fetching: false,
    error: null,
}

export function defaultReducerWithData(field) {
    return {
        PENDING: (state, action) => {
            const { payload } = action
            if (payload && payload.polling) {
                return state
            }

            return state.update(field, (data) => data.set('fetched', false).set('fetching', true))
        },
        RESET: (state) =>
            state.update(field, (data) =>
                data.set('fetched', false).set('fetching', false).set('data', null)
            ),
        FULFILLED: (state, { payload }) => {
            return state.update(field, (data) =>
                data
                    .set('fetched', true)
                    .set('fetching', false)
                    .set('data', Immutable.fromJS(payload.data || payload))
            )
        },
        REJECTED: (state, { payload }) =>
            state.update(field, (data) =>
                data
                    .set('fetched', false)
                    .set('fetching', false)
                    .set('error', payload.response || payload)
            ),
    }
}

export function defaultReducerWithoutData(field) {
    return {
        PENDING: (state, action) => {
            const { payload } = action
            if (payload && payload.polling) {
                return state
            }

            return state.update(field, (data) => data.set('fetched', false).set('fetching', true))
        },
        RESET: (state) =>
            state.update(field, (data) => data.set('fetched', false).set('fetching', false)),
        FULFILLED: (state) =>
            state.update(field, (data) => data.set('fetched', true).set('fetching', false)),
        REJECTED: (state, { payload }) =>
            state.update(field, (data) =>
                data
                    .set('fetched', false)
                    .set('fetching', false)
                    .set('error', payload.response || payload)
            ),
    }
}

export function defaultReducerReset(field) {
    return (state) =>
        state.update(field, (data) =>
            data.set('fetched', false).set('fetching', false).set('data', null)
        )
}

export function defaultReducerClearErrors(fields) {
    return (state) => {
        fields.forEach((field) => {
            state.update(field, (data) => data.set('error', null))
        })

        return state
    }
}

export function defaultReducerUpdateLocal(state, payload, field) {
    const updatedData = {
        ...state.get(field).toJS().data,
        ...payload,
    }
    return state.update(field, (data) => data.set('data', Immutable.fromJS(updatedData)))
}
