import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga';
import {reducers, sagas} from './reducers';

export default function configureStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer]
    const composedEnhancers = composeWithDevTools(...enhancers)

    const store = createStore(reducers, preloadedState, composedEnhancers)

    sagaMiddleware.run(sagas);

    // if (process.env.NODE_ENV !== 'production' && module.hot) {
    //     module.hot.accept('./reducers', () => store.replaceReducer(reducers))
    // }

    return store
}