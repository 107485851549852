import React from 'react'
import ContentfulLink from '../../class/models/link'
import LinkGroup from '../../class/models/link-group'
import LinkGroupOrientation from '../../enums/LinkGroupOrientation'
import CustomLink from '../CustomLink/CustomLink'

interface IProps {
    data: LinkGroup
}

export default class LinkGroupComponent extends React.Component<IProps> {
    render() {
        const { data } = this.props
        return data.orientation === LinkGroupOrientation.ROW ? (
            <div className="row align-items-center justify-content-center">
                {data.links.map((link: ContentfulLink, index: number) => (
                    <div className="col-md flex-grow-0 no-wrap text-center" key={index}>
                        <CustomLink data={link} className="w-md-auto w-100" />
                    </div>
                ))}
            </div>
        ) : null
    }
}
