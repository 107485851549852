import {handleActions} from 'redux-actions';
import * as Immutable from 'immutable';
import { defaultFieldWithData, defaultFieldWithoutData, defaultReducerWithData } from './utils';

const initialState = Immutable.fromJS({
    quizzes: defaultFieldWithData,
    putQuizAnswer: defaultFieldWithoutData,
});

export default handleActions({
    QUIZZES: {
        FETCH_QUIZZES: defaultReducerWithData('quizzes'),
        PUT_QUIZ_ANSWER: defaultReducerWithData('putQuizAnswer'),
        CLEAR_ERRORS: (state) => state
            .update('quizzes', (data) => data.set('error', null))
            .update('putQuizAnswer', (data) => data.set('error', null))
    },
}, initialState);
