import ButtonStyle from '../../enums/ButtonStyle'
import WidthUnit, { widthUnitMapping } from '../../enums/WidthUnit'

export default class ContentfulLink {
    title: string
    link: string
    internalexternal: 'Internal' | 'External'
    button: boolean
    style: ButtonStyle
    customBackgroundColor?: string
    textColor?: string
    fontSize?: number
    width?: string

    constructor(data: any) {
        const widthUnit: WidthUnit = data.widthUnit
        this.title = data.title
        this.link = data.link
        this.internalexternal = data.internalexternal
        this.button = data.button
        this.style = data.style || ButtonStyle.PRIMARY
        this.customBackgroundColor = data.customBackgroundColor || undefined
        this.textColor = data.textColor || undefined
        this.fontSize = data.fontSize || undefined
        this.width = data.linkWidth
            ? `${data.linkWidth}${
                  widthUnit ? widthUnitMapping[widthUnit] : widthUnitMapping[WidthUnit.PIXELS]
              }`
            : undefined
    }
}
