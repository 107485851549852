import React from 'react'
import SponsorsSection from '../../class/models/sponsors-section'
import SponsorsSectionTemplateThree from '../../components/SponsorsSection/SponsorSectionTemplateThree/SponsorSectionTemplateThree.component'
import SponsorsSectionTemplateOne from '../../components/SponsorsSection/SponsorsSectionTemplateOne/SponsorsSectionTemplateOne.component'
import SponsorsSectionTemplateTwo from '../../components/SponsorsSection/SponsorsSectionTemplateTwo/SponsorsSectionTemplateTwo.component'
import { Template } from '../../enums/TemplateTypes'

interface ISponsorsSection {
    data: SponsorsSection
}

const SponsorsSectionContainer = ({ data }: ISponsorsSection) => {
    return data.sponsorGroups ? (
        <div className="sponsor-groups-wrap">
            {data.template === Template.TEMPLATE1 && <SponsorsSectionTemplateOne data={data} />}
            {data.template === Template.TEMPLATE2 && <SponsorsSectionTemplateTwo data={data} />}
            {data.template === Template.TEMPLATE3 && <SponsorsSectionTemplateThree data={data} />}
        </div>
    ) : null
}

export default SponsorsSectionContainer
