import React from 'react'
import ComponentsList from '../../enums/ComponentsList'
import CustomContent from '../CustomContent/CustomContent.component'
import CustomCopy from '../CustomCopy/CustomCopy'
import CustomImage from '../CustomImage/CustomImage'
import CustomImageGroup from '../CustomImageGroup/CustomImageGroup'
import CustomLink from '../CustomLink/CustomLink'
import CustomTitle from '../CustomTitle/CustomTitle'
import PrizeSection from '../PrizeSection/PrizeSection.component'
import SponsorsSectionContainer from '../../containers/SponsorsSectionContainer/SponsorsSectionContainer'
import VideoChatSectionComponent from '../VideoChatSection/VideoChatSection.component'
import Title from '../../class/models/title'
import LinkGroupComponent from '../LinkGroup/LinkGroup.component'
import VideoEmbedComponent from '../VideoEmbed/VideoEmbed'
import SpeakersSectionContainer from '../../containers/SpeakersSectionContainer/SpeakersSectionContainer.component'

interface IDynamicContent {
    type: ComponentsList
    data: any
    title?: Title
}

const DynamicContent: React.FC<IDynamicContent> = ({ type, data, title }: IDynamicContent) => {
    if (type === ComponentsList.CustomTitle) {
        return <CustomTitle data={data} />
    } else if (type === ComponentsList.CustomCopy) {
        return <CustomCopy data={data} />
    } else if (type === ComponentsList.CustomImage) {
        return <CustomImage data={data} />
    } else if (type === ComponentsList.CustomImageGroup) {
        return <CustomImageGroup data={data} />
    } else if (type === ComponentsList.CustomLink) {
        return <CustomLink data={data} />
    } else if (type === ComponentsList.Sponsors) {
        return <SponsorsSectionContainer data={data} />
    } else if (type === ComponentsList.SpeakersSection) {
        return <SpeakersSectionContainer data={data} />
    } else if (type === ComponentsList.PrizeSection) {
        return <PrizeSection data={data} />
    } else if (type === ComponentsList.CustomContent) {
        return <CustomContent data={data} />
    } else if (type === ComponentsList.VideoChatSection) {
        return <VideoChatSectionComponent title={title || new Title({})} />
    } else if (type === ComponentsList.LinkGroup) {
        return <LinkGroupComponent data={data} />
    } else if (type === ComponentsList.VideoEmbed) {
        return <VideoEmbedComponent data={data} />
    } else {
        return null
    }
}

export default DynamicContent
