import React from 'react'
import { useSelector } from 'react-redux'
import { IPropWithData } from '../../../class/interfaces/redux'
import Sponsor from '../../../class/models/sponsor'
import SponsorGroup from '../../../class/models/sponsor-group'
import SponsorsSection from '../../../class/models/sponsors-section'
import Website from '../../../class/models/website'
import { selectWebsite } from '../../../redux/selectors/cms'
import CustomImage from '../../CustomImage/CustomImage'
import CustomTitle from '../../CustomTitle/CustomTitle'
import classList from './SponsorsSectionTemplateTwo.module.scss'

interface ISponsorsSection {
    data: SponsorsSection
}

const SponsorsSectionTemplateTwo = ({ data: { sponsorGroups } }: ISponsorsSection) => {
    const website = useSelector<any, IPropWithData<Website>>(selectWebsite)

    const settings = website.data ? website.data.settings : undefined

    const headingStyles: any = {}

    if (settings && settings.secondaryColor) {
        headingStyles.color = settings.secondaryColor
    }

    return sponsorGroups ? (
        <div className="sponsor-groups-wrap mt-7">
            {sponsorGroups.map((sponsorGroup: SponsorGroup, index: number) => (
                <div className="mb-7" key={index}>
                    {(sponsorGroup.groupTitle || sponsorGroup.title) && (
                        <div className="mb-3">
                            {sponsorGroup.groupTitle ? (
                                <CustomTitle data={sponsorGroup.groupTitle} />
                            ) : (
                                <>
                                    {sponsorGroup.title && (
                                        <h3
                                            className="h5 mb-0 text-center text-uppercase font-weight-normal"
                                            style={headingStyles}
                                        >
                                            {sponsorGroup.title}
                                        </h3>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {sponsorGroup.sponsors.map((sponsor: Sponsor, sponsorIndex: number) => (
                        <div key={sponsorIndex} className={classList.sponsor}>
                            {sponsor.image && <CustomImage data={sponsor.image} />}
                            {sponsor.title && (
                                <h4 className="text-center text-uppercase">{sponsor.title}</h4>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    ) : null
}

export default SponsorsSectionTemplateTwo
