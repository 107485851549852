import React from 'react'
import ContentCollectionItem from '../../class/models/content-collection'
import Section from '../../class/models/section'
import DynamicContent from '../DynamicContent/DynamicContent'
import View from '../View/View.component'
import classNames from 'classnames'
import CustomTitle from '../CustomTitle/CustomTitle'
import SectionContentDisplay from '../../enums/SectionContentDisplay'
import ComponentsList from '../../enums/ComponentsList'

interface ISection {
    data: Section
}

const SectionContent = ({ data }: ISection) => {
    const sectionStyles: any = {
        textAlign: data.textAlignment,
    }
    const viewStyles: any = {}
    const innerContentStyles: any = {}
    let containerClassList = []

    if (data.backgroundColor) {
        sectionStyles.backgroundColor = data.backgroundColor
    }
    if (data.backgroundImage) {
        sectionStyles.backgroundImage = `url(${data.backgroundImage.url})`
        sectionStyles.backgroundRepeat = 'no-repeat'
        sectionStyles.backgroundSize = 'cover'
        sectionStyles.backgroundPosition = 'top'
    }
    if (data.textColor) {
        sectionStyles.color = data.textColor
    }

    if (data.minHeight) {
        sectionStyles.minHeight = data.minHeight
    }

    if (data.paddingTop) {
        viewStyles.paddingTop = data.paddingTop
    }

    if (data.paddingBottom) {
        viewStyles.paddingBottom = data.paddingBottom
    }

    if (data.paddingLeft) {
        viewStyles.paddingLeft = data.paddingLeft
        if (data.paddingLeft === '0px') {
            containerClassList.push('pl-0')
        }
    }

    if (data.paddingRight) {
        viewStyles.paddingRight = data.paddingRight
        if (data.paddingLeft === '0px') {
            containerClassList.push('pr-0')
        }
    }

    if (data.marginTop) {
        viewStyles.marginTop = data.marginTop
    }

    if (data.marginBottom) {
        viewStyles.marginBottom = data.marginBottom
    }

    if (data.innerBackgroundColor) {
        viewStyles.backgroundColor = data.innerBackgroundColor
    }

    if (data.innerBackgroundImage) {
        viewStyles.backgroundImage = `url(${data.innerBackgroundImage.url})`
        viewStyles.backgroundRepeat = 'no-repeat'
        viewStyles.backgroundSize = 'cover'
        viewStyles.backgroundPosition = 'top'
    }

    const isFullSection = (contentType: string) => {
        return (
            contentType === 'SponsorsSection' ||
            contentType === 'SpeakersSection' ||
            contentType === 'VideochatSection'
        )
    }

    const beforeVideoChat: ContentCollectionItem[] = []
    const afterVideoChat: ContentCollectionItem[] = []
    let videoChatContent: ContentCollectionItem | null = null

    // Pull videoChat out of the flow so that we can have a custom view width. Needs to be wider.
    data.content.forEach((content: ContentCollectionItem) => {
        if (content.type === 'VideochatSection') {
            videoChatContent = content
        } else {
            if (!!videoChatContent) {
                afterVideoChat.push(content)
            } else {
                beforeVideoChat.push(content)
            }
        }
    })

    const displayContent = (content: ContentCollectionItem[], isFirst?: boolean) => {
        // Only display the title if this is the first set of content and there is no video chat.

        return content && !!content.length ? (
            <>
                {data.title && isFirst && !videoChatContent && <CustomTitle data={data.title} />}
                {(data.contentDisplay === SectionContentDisplay.ONE_THIRD ||
                    data.contentDisplay === SectionContentDisplay.ONE_HALF ||
                    data.contentDisplay === SectionContentDisplay.FLEX) && (
                    <div
                        className={classNames('row justify-content-center', {
                            'align-items-center':
                                data.contentDisplay === SectionContentDisplay.FLEX,
                        })}
                    >
                        {content.map((content: ContentCollectionItem, index: number) =>
                            isFullSection(content.type) ? (
                                <div className="col-12" key={index}>
                                    <DynamicContent
                                        type={content.type}
                                        data={content.data}
                                        title={data.title}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={classNames({
                                        'col-lg-4':
                                            data.contentDisplay === SectionContentDisplay.ONE_THIRD,
                                        'col-lg-6':
                                            data.contentDisplay === SectionContentDisplay.ONE_HALF,
                                        'col-lg':
                                            data.contentDisplay === SectionContentDisplay.FLEX,
                                    })}
                                    key={index}
                                >
                                    <DynamicContent
                                        type={content.type}
                                        data={content.data}
                                        title={data.title}
                                    />
                                </div>
                            )
                        )}
                    </div>
                )}
                {data.contentDisplay === SectionContentDisplay.ONE_THIRD_TWO_THIRD && (
                    <div className="row justify-content-center">
                        {data.content.map((content: ContentCollectionItem, index: number) =>
                            isFullSection(content.type) ? (
                                <div className="col-12" key={index}>
                                    <DynamicContent
                                        type={content.type}
                                        data={content.data}
                                        title={data.title}
                                    />
                                </div>
                            ) : (
                                <div
                                    key={index}
                                    className={classNames({
                                        'col-lg-4': index % 2 === 0,
                                        'col-lg-8': index % 2 !== 0,
                                    })}
                                >
                                    <DynamicContent
                                        type={content.type}
                                        data={content.data}
                                        title={data.title}
                                    />
                                </div>
                            )
                        )}
                    </div>
                )}
                {data.contentDisplay === SectionContentDisplay.STACKED && (
                    <div className="row">
                        {data.content.map((content: ContentCollectionItem, index: number) => (
                            <div key={index} className="col-12">
                                <DynamicContent
                                    type={content.type}
                                    data={content.data}
                                    title={data.title}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </>
        ) : null
    }

    const videoViewStyles: any = {}

    if (beforeVideoChat.length) {
        videoViewStyles.paddingTop = 0
    }

    // We don't want this component to handle the title if it is the video chat section since there is dynamic logic tied to it.
    return (
        <section
            style={sectionStyles}
            className={classNames({ 'd-flex align-items-center': !!data.minHeight })}
        >
            <View
                containerFluid={data.fullWidth}
                className={classNames(
                    { 'overflow-hidden': data.fullWidth, 'px-0': !data.mainGridGutters },
                    containerClassList.join(' ')
                )}
                containerVideo={!!videoChatContent}
                style={viewStyles}
            >
                <div style={innerContentStyles}>
                    {displayContent(beforeVideoChat, true)}
                    {videoChatContent && (
                        <div
                            className={classNames({
                                'mb-5': afterVideoChat && !!afterVideoChat.length,
                            })}
                        >
                            <DynamicContent
                                type={ComponentsList.VideoChatSection}
                                data={videoChatContent}
                                title={data.title}
                            />
                        </div>
                    )}
                    {displayContent(afterVideoChat)}
                </div>
            </View>
        </section>
    )
}

export default SectionContent
