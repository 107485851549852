import { Template } from '../../enums/TemplateTypes'
import SponsorGroup from './sponsor-group'

export default class SponsorsSection {
    template: Template
    sponsorGroups: SponsorGroup[]

    constructor(data: any, sponsorGroups: any) {
        this.template = data.template || Template.TEMPLATE1
        this.sponsorGroups = sponsorGroups
            ? sponsorGroups.map((sponsorGroup: any) => new SponsorGroup(sponsorGroup))
            : []
    }
}
