import { getSubdomain } from '../services/utils'
import Amplitude from './amplitude'

export class AnalyticsProvider {
    analytics: any

    constructor() {
        this.init()
    }

    private init = (): void => {
        var win: any = window
        this.analytics = win.analytics
    }

    private isInit = (): Boolean => {
        return !!this.analytics
    }

    private identifyUser = (userID: string, properties: any) => {
        Amplitude.identify(userID, { ...properties, domain: getSubdomain() })
        this.analytics.identify(userID, { ...properties, domain: getSubdomain() })
    }

    private trackEvent = (eventType: string, properties?: any, userId?: string) => {
        Amplitude.logEvent(eventType, { ...properties, domain: getSubdomain() })
        this.analytics.track(eventType, { ...properties, domain: getSubdomain, user_id: userId })
    }

    track = (event: string, properties: any): void => {
        try {
            if (!this.isInit()) {
                this.init()
            }

            if (!event) {
                var errorProperties = {
                    ...properties,
                    error_message: 'Event was not passed with Track Request',
                }
                this.trackEvent('Failed Track Event', errorProperties)
                return
            }
            this.trackEvent(event, { ...properties })
        } catch (err: any) {
            console.error(`An Error has occurred: `, err)
            this.trackEvent('Failed Tracking Event', { message: err.message, ...err })
        }
    }

    identify = (userID: string, properties: any) => {
        try {
            if (!this.isInit()) {
                this.init()
            }

            if (!properties) {
                var errorProperties = { userID, error_message: 'Failed to identify User' }
                this.trackEvent('Failed Track Event', errorProperties)
                return
            }
            this.identifyUser(userID, properties)
        } catch (err: any) {
            console.error(`An Error has occurred: `, err)
            this.trackEvent(
                'Failed to identify user',
                { userID, ...properties, message: err.message, ...err },
                userID
            )
        }
    }
}

const Analytics = new AnalyticsProvider()

export default Analytics
