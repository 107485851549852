import ElementAlignment from '../../enums/ElementAlignment'
import SectionContentDisplay from '../../enums/SectionContentDisplay'
import ContentCollectionItem from './content-collection'
import ContentfulMediaAsset from './ContentfulMediaAsset'
import Title from './title'

export default class Section {
    title: Title
    fullWidth: boolean
    mainGridGutters: boolean
    backgroundColor?: string
    backgroundImage?: ContentfulMediaAsset
    textColor?: string
    paddingTop?: string
    paddingBottom?: string
    paddingLeft?: string
    paddingRight?: string
    marginTop?: string
    marginBottom?: string
    minHeight?: string
    innerBackgroundColor?: string
    innerBackgroundImage?: ContentfulMediaAsset
    textAlignment: ElementAlignment
    contentDisplay: SectionContentDisplay
    content: ContentCollectionItem[]

    constructor(
        data: any,
        sponsorsData: any,
        speakersData: any,
        prizesData: any,
        videoChatData: any
    ) {
        this.title = new Title(data.title)
        this.fullWidth = !!data.fullWidth
        this.mainGridGutters = !!data.mainGridGutters
        this.backgroundColor = data.backgroundColor
        this.backgroundImage = data.backgroundImage
            ? new ContentfulMediaAsset(data.backgroundImage)
            : undefined
        this.textColor = data.textColor
        this.paddingTop =
            data.paddingTop !== null && data.paddingTop !== undefined
                ? `${data.paddingTop}px`
                : undefined
        this.paddingBottom =
            data.paddingBottom !== null && data.paddingBottom !== undefined
                ? `${data.paddingBottom}px`
                : undefined
        this.paddingLeft =
            data.paddingLeft !== null && data.paddingLeft !== undefined
                ? `${data.paddingLeft}px`
                : undefined
        this.paddingRight =
            data.paddingRight !== null && data.paddingRight !== undefined
                ? `${data.paddingRight}px`
                : undefined
        this.marginTop =
            data.marginTop !== null && data.marginTop !== undefined
                ? `${data.marginTop}px`
                : undefined
        this.marginBottom =
            data.marginBottom !== null && data.marginBottom !== undefined
                ? `${data.marginBottom}px`
                : undefined
        this.minHeight = data.minHeight !== null ? `${data.minHeight}px` : undefined
        this.innerBackgroundColor = data.innerBackgroundColor || undefined
        this.innerBackgroundImage = data.innerBackgroundImage
            ? new ContentfulMediaAsset(data.innerBackgroundImage)
            : undefined
        this.textAlignment = data.textAlignment || ElementAlignment.Left
        this.contentDisplay = data.contentDisplay || SectionContentDisplay.STACKED
        this.content =
            data.contentCollection && data.contentCollection.items
                ? data.contentCollection.items.map(
                      (contentCollectionItem: any) =>
                          new ContentCollectionItem(
                              contentCollectionItem,
                              sponsorsData,
                              speakersData,
                              prizesData,
                              videoChatData
                          )
                  )
                : []
    }
}
