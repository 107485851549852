import ContentfulMediaAsset from './ContentfulMediaAsset'

export default class BreakoutRoom {
    title: string
    subdomain: string
    eventDate: string
    eventEndDate: string
    favicon?: ContentfulMediaAsset
    sidebarTitle?: string
    sidebarDonationUrl?: string
    requireLogin: Boolean
    videoUrl: string
    hostEmail: string | null

    constructor(data: any) {
        this.title = data.title
        this.subdomain = data.subdomain
        this.eventDate = data.eventDate
        this.eventEndDate = data.eventEndDate
        this.favicon = data.favicon ? new ContentfulMediaAsset(data.favicon) : undefined
        this.sidebarTitle = data.sidebarTitle || undefined
        this.sidebarDonationUrl = data.sidebarDonationUrl || undefined
        this.requireLogin = data.requireLogin || false
        this.videoUrl = data.videoUrl
        this.hostEmail = data.hostEmail ? data.hostEmail.toLowerCase() : null
    }
}
