import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './scss/index.scss'
import App from './containers/App/App'
import reportWebVitals from './reportWebVitals'
import configureStore from './redux/configureStore'
import Amplify from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)

export const store = configureStore()

const renderApp = () =>
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    )

// if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
//   (module as any).hot.accept('./components/App', renderApp)
// }

renderApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
