import React from 'react'
import { connect } from 'react-redux'
import { selectIsPostShow, selectWebsite } from '../../../redux/selectors/cms'
import classList from './IframePlayer.module.scss'
import classNames from 'classnames'
import { ChatType } from '../../../class/models/video-chat'

const mapStateToProps: any = (state: any) => {
    return {
        websiteData: selectWebsite(state),
        isPostShow: selectIsPostShow(state),
    }
}

class IframePlayerComponent extends React.Component<any> {
    render() {
        const {
            isPostShow,
            websiteData: {
                data: {
                    videoChat: { iframeUrl, chatType },
                },
            },
        } = this.props
        const usingCustomChat = chatType === ChatType.CUSTOM
        const url = new URL(iframeUrl)
        url.searchParams.append('autoplay', '1')
        return iframeUrl ? (
            <iframe
                className={classNames(classList.iframe, {
                    [classList['post-show']]: isPostShow,
                    [classList['no-border-radius']]: !usingCustomChat,
                })}
                src={url.href}
                title="Event Video"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
            />
        ) : null
    }
}

const IframePlayer = connect(mapStateToProps)(IframePlayerComponent)

export default IframePlayer
