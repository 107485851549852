import React from 'react'
import moment from 'moment'
import styles from './VideoChatSection.module.scss'
import Countdown from 'react-countdown'
import CustomLink from '../CustomLink/CustomLink'
import SignIn from '../Signin/Signin'
import UserService from '../../services/user'
import { connect, ConnectedProps } from 'react-redux'
import { selectUser } from '../../redux/selectors/auth'
import User from '../../class/models/user'
import PollsContainer from '../PollsContainer/PollsContainer.component'
import { selectIsPostShow, selectWebsite } from '../../redux/selectors/cms'
import { setIsPostShow } from '../../redux/actions/cms'
import VideoContainer from '../../containers/VideoContainer/VideoContainer'
import Title from '../../class/models/title'
import CustomTitle from '../CustomTitle/CustomTitle'
import Quizzes from '../Quizzes/Quizzes.component'
import ChatContainer from '../../containers/ChatContainer/ChatContainer.component'
import classNames from 'classnames'
import CustomCopy from '../CustomCopy/CustomCopy'
import ClosedCaption from '../ClosedCaption/ClosedCaption.component'
import { IPropWithData } from '../../class/interfaces/redux'
import Website from '../../class/models/website'
import { isChatValid } from '../../services/utils'

interface IStateProps {
    userData?: User
    websiteData: IPropWithData<Website>
    isPostShow?: boolean
}

interface IOwnProps {
    title: Title
}

interface IState {
    isComplete: boolean
    showBreakoutButtons: boolean
    breakoutInterval?: number
    isEndOfShow: boolean
    eventStarted: boolean
}

const mapStateToProps = (state: any): IStateProps => {
    return {
        userData: selectUser(state),
        websiteData: selectWebsite(state),
        isPostShow: selectIsPostShow(state),
    }
}

const actions = { setIsPostShow }

const connector = connect(mapStateToProps, actions)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux & IOwnProps

class VideoChatSectionComponentClass extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            isComplete:
                props.websiteData.data && props.websiteData.data.eventDate
                    ? moment().isSameOrAfter(moment(props.websiteData.data.eventDate))
                    : false,
            showBreakoutButtons: false,
            breakoutInterval: undefined,
            isEndOfShow: false,
            eventStarted: false,
        }
    }

    componentDidMount() {
        const {
            websiteData: { data: websiteData },
        } = this.props

        const now = moment()
        const hourAfterShow = moment(websiteData.eventEndDate).add(1, 'hour')

        /**
         * Check if current time is the same or after the midnight before the event.
         * If yes, set isComplete to true so the user can enter the event.
         * Check if current time is the same or after one hour after the event end time.
         */
        this.props.setIsPostShow(now.isSameOrAfter(hourAfterShow))
        this.setState(
            {
                isComplete: now.isSameOrAfter(this.getLoginTimeMoment()),
            },
            () => {
                this.checkShowBreakoutButtons()
                const breakoutInterval = window.setInterval(() => {
                    this.checkShowBreakoutButtons()
                }, 1000)

                this.setState({ breakoutInterval })
            }
        )
    }

    getLoginTimeMoment = () => {
        const {
            websiteData: { data: websiteData },
        } = this.props

        const midnightBefore = moment(websiteData.eventDate).subtract(1, 'day').endOf('day')

        return websiteData.settings && websiteData.settings.showLoginTime
            ? moment(websiteData.settings.showLoginTime)
            : midnightBefore
    }

    onComplete = () => {
        this.setState({
            isComplete: true,
        })
    }

    openBreakout = () => {
        window.open(UserService.buildBreakoutLink(), '', 'postwindow')
    }

    /**
     * Decides if breakout rooms should be displayed or now.
     * Event must be open for users and the user must have a roomId.
     * There is a one second interval that runs this method if certain criteria are met.
     * Will show if:
     * * Within 35 minutes of the event start. (value from CMS)
     * * Within 5 minutes of the projected end time of the event. (value from CMS)
     * Will hide if:
     * * After event start and before 5 minutes before end.
     * @returns undefined;
     */
    checkShowBreakoutButtons = () => {
        const { isComplete, breakoutInterval, eventStarted } = this.state
        const {
            userData,
            websiteData: { data },
            isPostShow,
        } = this.props

        const now = moment()
        const eventStart = moment(data.eventDate)
        const showBreakoutBeforeEventTime = moment(data.eventDate).subtract(20, 'minutes')
        const fiveMinutesBeforeEventEnd = moment(data.eventEndDate).subtract(5, 'minutes')

        if (now.isSameOrAfter(eventStart) && !eventStarted) {
            this.setState({ eventStarted: true })
        }

        if (isComplete && userData && userData.roomId) {
            if (now.isSameOrAfter(eventStart)) {
                if (now.isSameOrAfter(fiveMinutesBeforeEventEnd)) {
                    if (breakoutInterval) {
                        window.clearInterval(breakoutInterval)
                    }
                    this.setState({
                        // showBreakoutButtons: !isPostShow,
                        // isEndOfShow: true,
                        breakoutInterval: undefined,
                    })
                    return
                }
                this.setState({ showBreakoutButtons: false })
                return
            }
            if (now.isSameOrAfter(showBreakoutBeforeEventTime)) {
                this.setState({ showBreakoutButtons: !isPostShow })
            }
            return
        }
    }

    render() {
        const {
            websiteData: {
                data: { eventDate, pollIds, settings, videoChat: data, showQuiz, breakoutRoom },
            },
            isPostShow,
            title,
        } = this.props
        const { isComplete, showBreakoutButtons, eventStarted } = this.state

        const eventDateParsed = `${moment(eventDate).format('MMMM Do, YYYY @ h:mm A')} PST`

        // Drop countdown 30 minutes before event.
        const countdownTo = moment(eventDate).valueOf()
        const isLoggedIn = UserService.isLoggedIn()

        const textColorStyles = {
            color: !!data && settings && settings.primaryColor ? settings.primaryColor : '#ffffff',
        }

        const eventDateTitle = data ? data.eventDateTitle.replace('{date}', eventDateParsed) : ''
        const showBelowContent =
            data &&
            (data.showBelowContentBeforeEvent ||
                (!data.showBelowContentBeforeEvent && isComplete && isLoggedIn))

        const user = UserService.getUserProperties()

        return (
            <div className={styles['container-video']}>
                {!eventStarted ? (
                    <h2 className="text-center" style={textColorStyles}>
                        {eventDateTitle}
                    </h2>
                ) : (
                    <CustomTitle data={title} />
                )}

                {breakoutRoom &&
                isLoggedIn &&
                settings &&
                settings.showBreakoutRoomOverride &&
                user.roomId ? (
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            type="button"
                            // tslint:disable-next-line jsx-no-lambda
                            onClick={() => this.openBreakout()}
                            className="btn btn-secondary"
                        >
                            Breakout Room
                        </button>
                    </div>
                ) : breakoutRoom && isLoggedIn && user.roomId && showBreakoutButtons ? (
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="px-2">
                            <div className="d-flex align-items-center justify-content-center mb-3">
                                <button
                                    type="button"
                                    // tslint:disable-next-line jsx-no-lambda
                                    onClick={() => this.openBreakout()}
                                    className="btn btn-secondary"
                                >
                                    Breakout Room
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
                {isComplete && isLoggedIn && data && data.captionerIframe && (
                    <ClosedCaption
                        iframe={data.captionerIframe}
                        textColorStyle={textColorStyles}
                        titleColor={title.textColor}
                    />
                )}
                <div className="mb-1">
                    {isComplete ? (
                        <>
                            {isPostShow || !isChatValid() ? (
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <VideoContainer isPostShow={isPostShow} />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {!isLoggedIn && !!settings && !!settings.login ? (
                                        <>
                                            <SignIn />
                                        </>
                                    ) : (
                                        <div className="row justify-content-around">
                                            <div className="col-lg-8">
                                                <VideoContainer />
                                            </div>
                                            <div className="col-lg-4">
                                                <ChatContainer />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Countdown
                            date={countdownTo}
                            // date={moment().add(1, 'second').valueOf()}
                            // tslint:disable-next-line jsx-no-lambda
                            renderer={(props) => CountdownRenderer({ ...props, textColorStyles })}
                            onComplete={this.onComplete}
                        />
                    )}
                </div>
                {showBelowContent && data && (data.belowVideoCopy || data.button) && (
                    <div className="row">
                        <div
                            className={classNames('col-md-9 offset-md-1-5', {
                                'mb-0': !data.button,
                            })}
                        >
                            {data.belowVideoCopy && (
                                <div className="row">
                                    <div
                                        className={classNames('col-md-10 offset-md-1', {
                                            'mb-0': !data.button,
                                        })}
                                    >
                                        <CustomCopy data={data.belowVideoCopy} />
                                    </div>
                                </div>
                            )}
                            {data.button && (
                                <div className="text-center">
                                    <CustomLink data={data.button} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {isComplete && isLoggedIn && (showQuiz || !!pollIds) && (
                    <div className="mt-5">
                        <PollsContainer hide={false} open={false} />
                        {showQuiz && <Quizzes />}
                    </div>
                )}
            </div>
        )
    }
}

const VideoChatSectionComponent = connect(mapStateToProps, actions)(VideoChatSectionComponentClass)
export default VideoChatSectionComponent

interface ICountdownProps {
    days: number
    hours: number
    minutes: number
    seconds: number
    completed: boolean
    textColorStyles: any
}

const CountdownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    textColorStyles,
}: ICountdownProps) => {
    return !completed ? (
        <div className="d-flex justify-content-center">
            <div className="d-flex align-items-center" style={textColorStyles}>
                <div className="pr-md-6 pr-4 text-enter">
                    <div className={styles['countdown-number']}>{days}</div>
                    <div className={styles['countdown-title']}>Days</div>
                </div>
                <div className="pr-md-6 pr-4 text-enter">
                    <div className={styles['countdown-number']}>{hours}</div>
                    <div className={styles['countdown-title']}>Hours</div>
                </div>
                <div className="pr-md-6 pr-4 text-enter">
                    <div className={styles['countdown-number']}>{minutes}</div>
                    <div className={styles['countdown-title']}>Minutes</div>
                </div>
                <div>
                    <div className={styles['countdown-number']}>{seconds}</div>
                    <div className={styles['countdown-title']}>Seconds</div>
                </div>
            </div>
        </div>
    ) : null
}
