import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { selectWebsite } from '../../redux/selectors/cms'
import CustomChat from '../../components/Chat/CustomChat/CustomChat'
import IFrameChat from '../../components/Chat/IFrameChat/IFrameChat.component'
import MinnitChat from '../../components/Chat/MinnitChat/MinnitChat.component'
import { IPropWithData } from '../../class/interfaces/redux'
import Website from '../../class/models/website'
import { ChatType } from '../../class/models/video-chat'

interface IStateProps {
    websiteData: IPropWithData<Website>
}

const mapStateToProps = (state: any): IStateProps => {
    return {
        websiteData: selectWebsite(state),
    }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux

class ChatContainerComponent extends React.Component<IProps> {
    render() {
        const {
            websiteData: {
                data: { videoChat },
            },
        } = this.props

        if (!videoChat) return null

        if (videoChat.chatType === ChatType.IFRAME) {
            return <IFrameChat />
        }

        if (videoChat.chatType === ChatType.CUSTOM) {
            return <CustomChat />
        }

        if (videoChat.chatType === ChatType.MINNIT) {
            return <MinnitChat />
        }

        return null
    }
}

const ChatContainer = connect(mapStateToProps)(ChatContainerComponent)
export default ChatContainer
