import LoginModel from "./login-model";

export default class Settings {
    primaryColor?: string
    secondaryColor?: string
    baseFontSize?: string
    containerMaxWidth?: string;
    showLoginTime?: string;
    showBreakouts: boolean;
    showBreakoutRoomOverride:boolean;
    amplitudeId?:string;
    login?: LoginModel;

    constructor(data: any) {
        this.primaryColor = data.primaryColor
        this.secondaryColor = data.secondaryColor
        this.baseFontSize = data.baseFontSize ? `${data.baseFontSize}px` : '16px'
        this.containerMaxWidth = data.containerMaxWidth ? `${data.containerMaxWidth}px` : undefined
        this.showLoginTime = data.showLoginTime
        this.showBreakouts = !!data.showBreakouts;
        this.showBreakoutRoomOverride = !!data.showBreakoutRoomOverride;
        this.amplitudeId = data.amplitudeId || null;
        this.login = data.login ? new LoginModel(data.login) : undefined;
    }
}
