import {
    CONTENTFUL_ASSET_FRAGMENT,
    CONTENT_COLLECTION_FRAGMENT,
    IMAGE_FRAGMENT,
    LINK_FRAGMENT,
    PRIZE_SECTION_FRAGMENT,
    PRIZE_FRAGMENT,
    SPEAKERS_SECTION_FRAGMENT,
    SPONSORS_SECTION_FRAGMENT,
    SPONSOR_GROUPS_FRAGMENT,
    TITLE_FRAGMENT,
    VIDEO_CHAT_SECTION_FRAGMENT,
    COPY_FRAGMENT,
    SPEAKER_GROUP_FRAGMENT,
} from '../fragments/contentful'
import { instanceApi } from './instances'

class ContentfulClass {
    async postGraphql(graphql: string) {
        try {
            return await instanceApi.post('/graphql', graphql)
        } catch (e) {
            throw e
        }
    }

    async getWebsiteSponsors(subDomain: string) {
        return await this.postGraphql(
            `{ 
        websiteCollection(where: {subdomain: "${subDomain}"}, limit: 1) { 
          items { 
            sponsorGroupsCollection(limit: 50) {
              items {
                ${SPONSOR_GROUPS_FRAGMENT}
              }
            }
          } 
        }
      }`
        ).then((response) => {
            if (!response.data.data.websiteCollection.items[0]) {
                return Promise.reject({ data: { message: 'No website found.' } })
            }
            return response.data.data.websiteCollection.items[0].sponsorGroupsCollection.items
        })
    }

    async getSpeakers(subDomain: string) {
        return await this.postGraphql(
            `{ 
        websiteCollection(where: {subdomain: "${subDomain}"}, limit: 1) { 
          items { 
            speakersCollection(limit: 50) {
              items {
                ${SPEAKER_GROUP_FRAGMENT}
              }
            }
          } 
        }
      }`
        ).then((response) => {
            if (!response.data.data.websiteCollection.items[0]) {
                return Promise.reject({ data: { message: 'No website found.' } })
            }
            return response.data.data.websiteCollection.items[0].speakersCollection.items
        })
    }

    async getPrizes(subDomain: string) {
        return await this.postGraphql(
            `{ 
        websiteCollection(where: {subdomain: "${subDomain}"}, limit: 1) { 
          items { 
            prizesCollection(limit: 50) {
              items {
                ${PRIZE_FRAGMENT}
              }
            }
          } 
        }
      }`
        ).then((response) => {
            if (!response.data.data.websiteCollection.items[0]) {
                return Promise.reject({ data: { message: 'No website found.' } })
            }
            return response.data.data.websiteCollection.items[0].prizesCollection.items
        })
    }

    async getSections(subDomain: string) {
        return await this.postGraphql(
            `{ 
        websiteCollection(where: {subdomain: "${subDomain}"}, limit: 1) { 
          items { 
            sectionsCollection(limit: 15) {
              items {
                title {${TITLE_FRAGMENT}}
                fullWidth
                mainGridGutters
                backgroundColor
                backgroundImage {${CONTENTFUL_ASSET_FRAGMENT}}
                textColor
                paddingTop
                paddingBottom
                paddingLeft
                paddingRight
                marginTop
                marginBottom
                minHeight
                innerBackgroundColor
                innerBackgroundImage {${CONTENTFUL_ASSET_FRAGMENT}}
                textAlignment
                contentDisplay
                contentCollection(limit: 25) {
                  items {
                    ${CONTENT_COLLECTION_FRAGMENT}
                    ... on Sponsors {${SPONSORS_SECTION_FRAGMENT}}
                    ... on GuestSpeakersSection {${SPEAKERS_SECTION_FRAGMENT}}
                    ... on PrizeSection {${PRIZE_SECTION_FRAGMENT}}
                    ... on VideochatSection {${VIDEO_CHAT_SECTION_FRAGMENT}}
                  }
                }
              }
            }
          } 
        }
      }`
        ).then((response) => {
            if (!response.data.data.websiteCollection.items[0]) {
                return Promise.reject({ data: { message: 'No website found.' } })
            }
            return response.data.data.websiteCollection.items[0].sectionsCollection.items
        })
    }

    async getWebsiteSettings(subDomain: string) {
        return await this.postGraphql(
            `
        {
          websiteCollection(where: {subdomain: "${subDomain}"}, limit: 1){
            items{
              title
              subdomain
              settings{
                amplitudeId
              }
            }
          }
        }`
        ).then((response) => {
            if (!response.data.data.websiteCollection.items[0]) {
                return Promise.reject({ data: { message: 'No website Settings found.' } })
            }
            return response.data.data.websiteCollection.items[0]
        })
    }

    /**
     * Get the website data for the specified subdomain.
     * ** Note ** the limits are added to the collection queries because of a complexity limit with Contentful's GraphQL API.
     * This could cause issues later.
     * @param subDomain the subdomain to query data for.
     */
    async getWebsite(subDomain: string) {
        return await this.postGraphql(
            `{ 
        websiteCollection(where: {subdomain: "${subDomain}"}, limit: 1) { 
          items { 
            sys {
              id
            }
            title
            subdomain
            eventDate
            eventEndDate
            logo {${IMAGE_FRAGMENT}}
            favicon {${CONTENTFUL_ASSET_FRAGMENT}}
            heroSection { 
              title
              image {${IMAGE_FRAGMENT}} 
              backgroundColor
              overlayImage {${IMAGE_FRAGMENT}}
              text
              maxHeight
              minHeight
              contentCollection {
                items {
                  ${CONTENT_COLLECTION_FRAGMENT}
                }
              }
              template
              paddingTop
              paddingBottom
            }
            videoChat {
              eventDateTitle
              videoPoster {${CONTENTFUL_ASSET_FRAGMENT}}
              streamUrl
              iframeUrl
              eventInstanceId
              channelId
              typeOfVideoToShow
              videoTitle
              videoLogo {${CONTENTFUL_ASSET_FRAGMENT}}
              chatType
              minnitChatId
              chatIFrame
              chatTitle
              chatLogo {${CONTENTFUL_ASSET_FRAGMENT}}
              showBelowContentBeforeEvent
              belowVideoCopy {${COPY_FRAGMENT}}
              button {${LINK_FRAGMENT}}
              captionerIframe
            }
            pollIds
            showQuiz
            footer {
              title
              backgroundImage {${CONTENTFUL_ASSET_FRAGMENT}}
              backgroundColor
              textColor
              iconColor
              linksCollection(limit:10) {
                items {${LINK_FRAGMENT}}
              }
              logo {
                image {${CONTENTFUL_ASSET_FRAGMENT}}
                alignment
                maxWidth
              }
              phone
              copyright
              socialMediaLinks
            }
            settings {
              primaryColor
              secondaryColor
              baseFontSize
              containerMaxWidth
              showLoginTime
              showBreakouts
              showBreakoutRoomOverride
              amplitudeId
              login {
                registrationFirstName
                registrationFirstNameRequired
                registrationLastName
                registrationLastNameRequired
                registrationEmail
                registrationEmailRequired
                inputBackgroundColor
                inputTextColor
                allowWalkIns
                requireLogin
                onlyShowGuestLogin
              }
            }
            breakoutRoom {
              subdomain
            }
          } 
        }
      }`
        ).then((response) => {
            if (!response.data.data.websiteCollection.items[0]) {
                return Promise.reject({ data: { message: 'No website found.' } })
            }
            return response.data.data.websiteCollection.items[0]
        })
    }
}

const Contentful = new ContentfulClass()

export default Contentful
