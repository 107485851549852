import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import styles from './PollsContainer.module.scss'
import { connect } from 'react-redux'
import { selectWebsite } from '../../redux/selectors/cms'

const mapStateToProps: any = (state: any) => {
    return {
        websiteData: selectWebsite(state),
    }
}

interface IPollsContainer {
    open: boolean
    hide?: boolean
    websiteData?: any
}

interface IState {
    isOpen: boolean
    isHidden?: boolean
}

class PollsContainerComponent extends React.Component<IPollsContainer, IState> {
    constructor(props: IPollsContainer) {
        super(props)
        this.state = {
            isOpen: props.open,
            isHidden: props.hide,
        }
    }

    setOpen = (isOpen: boolean) => {
        this.setState({ isOpen })
    }

    render() {
        const { isHidden, isOpen } = this.state
        const {
            websiteData: {
                data: { pollIds },
            },
        } = this.props
        return !isHidden && pollIds ? (
            <>
                <div className="d-flex justify-content-center mb-3">
                    <button
                        type="button"
                        className="btn btn-plain text-white d-flex align-items-center"
                        // tslint:disable-next-line jsx-no-lambda
                        onClick={() => this.setOpen(!isOpen)}
                    >
                        <div className="mr-2">{!isOpen ? 'View Polls' : 'Hide Polls'}</div>
                        <FontAwesomeIcon
                            className={styles['polls-icon']}
                            icon={!isOpen ? faAngleDoubleDown : faTimes}
                        />
                    </button>
                </div>

                <div hidden={!isOpen} className="row">
                    {pollIds.map((pollId: string, index: number) => (
                        <div className="col-md-4" key={index}>
                            <div className="powr-poll" id={pollId} />
                        </div>
                    ))}
                </div>
            </>
        ) : null
    }
}

const PollsContainer = connect(mapStateToProps)(PollsContainerComponent)
export default PollsContainer
