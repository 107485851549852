import Image from './image'

export default class Sponsor {
    title?: string
    image?: Image
    url?: string
    primarySponsor?: boolean

    constructor(data: any) {
        this.title = data.title
        this.image = data.image ? new Image(data.image) : undefined
        this.url = data.url
        this.primarySponsor = data.primarySponsor
    }
}
