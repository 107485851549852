export default class IntroSection {
    title: string
    content: string

    constructor(rawData: any) {
        const data = rawData || {}
        this.title = data.title
        this.content = data.content
    }
}
