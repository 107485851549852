import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { IPropWithData } from '../../class/interfaces/redux'
import FooterSection from '../../class/models/footer'
import SocialMediaLink from '../../class/models/social-media-link'
import Website from '../../class/models/website'
import { selectWebsite } from '../../redux/selectors/cms'
import CustomImage from '../CustomImage/CustomImage'
import SocialMediaIcon from '../SocialMediaLink/SocialMediaLink.component'
import View from '../View/View.component'
import styles from './Footer.module.scss'

interface IStateProps {
    websiteData: IPropWithData<Website>
}

const mapStateToProps = (state: any): IStateProps => {
    return {
        websiteData: selectWebsite(state),
    }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux

class FooterComponent extends React.Component<IProps> {
    render() {
        const {
            websiteData: {
                data: { footer, settings },
            },
        } = this.props

        const footerData = footer || new FooterSection({})

        const footerContainerStyles: any = {}

        if (footerData.backgroundImage) {
            footerContainerStyles.backgroundImage = `url(${footerData.backgroundImage.url})`
            footerContainerStyles.backgroundRepeat = 'no-repeat'
            footerContainerStyles.backgroundSize = 'cover'
            footerContainerStyles.backgroundPosition = 'top'
            // if(data.backgroundImage.asset.height) {
            //     imageContainerStyles.minHeight = `${data.backgroundImage.asset.height}px`
            // }
        }

        if (footer?.backgroundColor) {
            footerContainerStyles.backgroundColor = footer?.backgroundColor
        }

        footerContainerStyles.color = footerData.textColor || settings?.primaryColor || 'inherit'

        let formattedCopyright = ''

        if (footerData.copyright) {
            formattedCopyright = footerData.copyright
                .replace('{copyright}', '©')
                .replace('{year}', `${new Date().getFullYear()}`)
        }

        return (
            <footer className={styles.footer} style={footerContainerStyles}>
                <View>
                    {footerData.logo && (
                        <div className={styles.logo}>
                            <CustomImage data={footerData.logo} />
                        </div>
                    )}
                    {footerData.socialMediaLinks && !!footerData.socialMediaLinks.length && (
                        <div className="row justify-content-center align-items-center mb-5">
                            {footerData.socialMediaLinks.map(
                                (socialMediaLink: SocialMediaLink, index: number) => (
                                    <div className="col w-auto flex-grow-0" key={index}>
                                        <SocialMediaIcon
                                            data={socialMediaLink}
                                            color={footerData.iconColor}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    <div className="socket">
                        <div className="d-sm-flex align-items-center justify-content-center text-center text-md-left">
                            {footerData.phone && (
                                <div className="mr-5 mb-md-0 mb-3 small">{footerData.phone}</div>
                            )}
                            {footerData.copyright && (
                                <div className="small">{formattedCopyright}</div>
                            )}
                        </div>
                    </div>
                </View>
            </footer>
        )
    }
}

const Footer = connect(mapStateToProps)(FooterComponent)
export default Footer
