export default class SocialMediaLink {
    type: string
    url: string

    constructor(rawData: string) {
        const data = (rawData || '').split(';')
        this.type = data[0]
        this.url = data[1]
    }
}
