import {combineReducers} from 'redux';
import {all, call} from 'redux-saga/effects';
import auth from './auth';
import chat from './chat';
import cms from './cms';
import quizzes from './quizzes';
import { userWatcher } from '../actions/auth';
import { chatWatcher } from '../actions/chat';
import { cmsWatcher } from '../actions/cms';
import { quizzesWatcher } from '../actions/quizzes';

export const reducers = combineReducers({
    auth,
    chat,
    cms,
    quizzes,
});

export const sagas = function* () {
    yield all([
        call(userWatcher),
        call(chatWatcher),
        call(cmsWatcher),
        call(quizzesWatcher),
    ]);
};
