import ElementAlignment from '../../enums/ElementAlignment'
import { TextStyle } from '../../enums/Text'
import FontWeight from '../../enums/Title'
import Titles from '../../enums/Titles'

export default class Title {
    title: string
    type: Titles
    alignment: ElementAlignment
    fontWeight: FontWeight
    textColor?: string
    lineBottom: boolean
    linesLeftAndRight: boolean
    textStyle: TextStyle
    url?: string

    constructor(data: any) {
        const theData = data || {}
        this.title = theData.title
        this.type = theData.type ? theData.type.toLowerCase() : 'h2'
        this.alignment = theData.alignment || 'left'
        this.fontWeight = theData.fontWeight || FontWeight.Bold
        this.textColor = theData.textColor || '#000000'
        this.lineBottom = !!theData.lineBottom
        this.linesLeftAndRight = !!theData.linesLeftAndRight
        this.textStyle = theData.textStyle || TextStyle.UPPERCASE
        this.url = theData.url || undefined
    }
}
