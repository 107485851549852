import React from 'react'
import classList from './CustomChat.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrin, faSpinner } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import classNames from 'classnames'
import User from '../../../services/user'
import { connect } from 'react-redux'
import { logout } from '../../../redux/actions/auth'
import { fetchMessages } from '../../../redux/actions/chat'
import { selectUser } from '../../../redux/selectors/auth'
import Picker from 'emoji-picker-react'
import { selectGetMessages } from '../../../redux/selectors/chat'
import { getSubdomain } from '../../../services/utils'
import { selectWebsite } from '../../../redux/selectors/cms'
import Filter from 'bad-words'
import Button from '../../Button/Button.component'

interface IMessage {
    createdAt: string
    firstName: string
    lastName: string
    email: string
    message: string
}

interface IState {
    metadataId?: string
    message: string
    messages: IMessage[]
    active: boolean
    showEmojis: boolean
    showSignIn: boolean
    chatEnabled: boolean
    chatInterval?: number
    socketTimerId?: number
}

const mapStateToProps: any = (state: any) => {
    return {
        userData: selectUser(state),
        messagesData: selectGetMessages(state),
        websiteData: selectWebsite(state),
    }
}

const actions = {
    logout,
    fetchMessages,
}

let connection: WebSocket

class ChatComponent extends React.Component<any, IState> {
    chatRef: React.RefObject<HTMLInputElement> = React.createRef()
    messagesEndRef: React.RefObject<HTMLInputElement> = React.createRef()
    chatWrapperRef: React.RefObject<HTMLInputElement> = React.createRef()
    badWordFilter = new Filter()
    socketTimeout = 60000 // Ping server every minute to keep connection alive.

    constructor(props: any) {
        super(props)

        this.state = {
            metadataId: undefined,
            message: '',
            messages: [],
            active: false,
            showEmojis: false,
            showSignIn: false,
            chatEnabled: false,
            chatInterval: undefined,
        }
    }

    componentDidMount() {
        connection = new WebSocket(
            `${process.env.REACT_APP_CHAT_WEBSOCKET || ''}?subdomain=${getSubdomain()}`
        )
        this.props.fetchMessages()

        connection.onopen = (e) => {
            this.keepAlive()
        }

        connection.onclose = (event) => {
            this.cancelKeepAlive()
        }

        connection.onerror = (event) => {
            // tslint:disable-next-line no-console
            console.error('WebSocket error observed:', event)
        }

        connection.onmessage = (event) => {
            if (event.data) {
                const messages: IMessage[] = this.state.messages
                const parsedData = JSON.parse(event.data)
                const { firstName, lastName, email, message, createdAt } = parsedData

                messages.push({
                    createdAt,
                    firstName,
                    lastName,
                    message,
                    email,
                })

                this.setState({ messages })
            }
        }

        const chatInterval = window.setInterval(() => {
            this.checkShowChat()
        }, 1000)

        this.setState({ chatInterval })

        document.addEventListener('mousedown', this.handleClickOutsideEmojiPicker)
    }

    componentDidUpdate(prevProps: any) {
        // this.scrollToBottom();
        this.scrollToBottom()
        this.checkGetMessages(prevProps)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideEmojiPicker)
        connection.close()
    }

    checkGetMessages = (prevProps: any) => {
        const {
            messagesData: { fetched: prevFetched },
        } = prevProps
        const {
            messagesData: { fetched: currFetched, data },
        } = this.props

        if (!prevFetched && currFetched) {
            this.setState({
                messages: data,
            })
        }
    }

    scrollToBottom = () => {
        if (this.messagesEndRef.current) {
            this.messagesEndRef.current.scroll({
                top: this.messagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            })
        }
    }

    /**
     * Keep the connection alive by peridocially pinging the server.
     */
    keepAlive = () => {
        if (connection.readyState === connection.OPEN) {
            connection.send(
                JSON.stringify({
                    action: 'sendMessage',
                    data: {
                        subdomain: getSubdomain(),
                        type: 'KEEP_ALIVE',
                    },
                })
            )
        }
        const socketTimerId = window.setTimeout(this.keepAlive, this.socketTimeout)
        this.setState({
            socketTimerId,
        })
    }

    /**
     * Cancel the keep alive timeout if one exists.
     */
    cancelKeepAlive = () => {
        const { socketTimerId } = this.state
        if (socketTimerId) {
            window.clearTimeout(socketTimerId)
        }
    }

    handleSubmit = (e: any) => {
        e.preventDefault()
        const { message } = this.state
        const { userData } = this.props
        const subdomain = getSubdomain()
        const emojiEscapeString = ';;emojiEscape;;'
        const messageFull = `${emojiEscapeString}${message.replace(/\\/g, '\\\\')}`
        if (message) {
            const data = JSON.stringify({
                action: 'sendMessage',
                data: {
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    message: this.badWordFilter.clean(messageFull).replace(emojiEscapeString, ''),
                    subdomain,
                },
            })

            connection.send(data)

            this.setState({ message: '' })
        }
    }

    handleChange = (e: any) => {
        this.setState({ message: e.target.value })
    }

    parseUrls = (userInput: string) => {
        const urlRegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
        const formattedMessage = (userInput || '').replace(urlRegExp, (match) => {
            let formattedMatch = match
            if (!match.startsWith('http')) {
                formattedMatch = `http://${match}`
            }
            return `<a href=${formattedMatch} class="chat-line__link" target="_blank" rel="noopener noreferrer">${match}</a>`
        })
        return formattedMessage
    }

    handleKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            this.handleSubmit(e)
        }
    }

    onEmojiClick = (e: any, emojiObject: any) => {
        e.preventDefault()
        const { message } = this.state
        this.setState({ message: `${message}${emojiObject.emoji}`, showEmojis: false })
    }

    toggleShowEmoji = () => {
        this.setState((prevState) => {
            return {
                showEmojis: !prevState.showEmojis,
            }
        })
    }

    handleClickOutsideEmojiPicker = (event: any) => {
        const { showEmojis } = this.state
        if (
            showEmojis &&
            this.chatWrapperRef &&
            this.chatWrapperRef.current &&
            !this.chatWrapperRef.current.contains(event.target)
        ) {
            this.setState({ showEmojis: false })
        }
    }

    getUsername = (message: IMessage) => {
        if (message.firstName && message.lastName) {
            return `${message.firstName} ${message.lastName}`
        } else {
            return message.email
        }
    }

    renderMessages = () => {
        const { messages } = this.state
        const { userData } = this.props
        const messageClassNames = `${classList['my-message']} justify-content-end ml-auto`
        return messages.map((message: IMessage) => {
            const formattedMessage = this.parseUrls(message.message)
            const isMyMessage = message.email === userData.email
            return (
                <li key={message.createdAt} className="mb-2">
                    <div
                        className={`${classNames({
                            'justify-content-end': isMyMessage,
                        })} mb-2 d-flex align-items-center`}
                    >
                        <div className="d-flex align-items-center small">
                            {this.getUsername(message)}
                            {/* <div className="mx-2">
                                <FontAwesomeIcon icon={faCircle} size="xs" />
                            </div> */}
                        </div>
                        {/* <span className="message-data-time small">{moment(message.createdAt).fromNow()}</span> */}
                    </div>
                    <div
                        className={`${classNames(classList.message, {
                            [messageClassNames]: isMyMessage,
                        })} d-flex`}
                    >
                        <span dangerouslySetInnerHTML={{ __html: formattedMessage }} />
                    </div>
                </li>
            )
        })
    }

    /**
     * Disable chat and stop interval if within 10 minutes of event.
     */
    checkShowChat = () => {
        const {
            websiteData: { data },
            isPostEvent,
        } = this.props
        const countdownTo = moment(data.eventDate).subtract(10, 'minutes')
        const now = moment()

        const isWithinEnableChatTime = now.isSameOrAfter(countdownTo)

        if (isWithinEnableChatTime) {
            this.setState((prevState) => {
                if (prevState.chatInterval) {
                    window.clearInterval(prevState.chatInterval)
                }
                return {
                    chatEnabled: !isPostEvent,
                    chatInterval: undefined,
                }
            })
        }
    }

    render() {
        const { message, showEmojis, chatEnabled } = this.state
        const {
            messagesData,
            websiteData: { data },
        } = this.props

        const videoChat = data.videoChat
        const chatBodyStyle: any = {}

        if (data.settings && data.settings.primaryColor) {
            chatBodyStyle.borderBottom = '1px solid'
            chatBodyStyle.borderTop = '1px solid'
            chatBodyStyle.borderColor = `${data.settings.primaryColor}`
        }

        return (
            <>
                <div className={`${classList.chat} h-100 d-flex flex-column bg-white`}>
                    <div className="chat-header p-2 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            {videoChat.logo && videoChat.logo.asset && videoChat.logo.asset.url && (
                                <img src={videoChat.logo.asset.url} alt="avatar" />
                            )}

                            <div className="pl-2 mt-2">
                                <div className="font-weight-bold body-base">
                                    {videoChat.chatTitle}
                                </div>
                            </div>
                        </div>
                        <div>
                            <i className="fa fa-star text-gray body-large" />
                        </div>
                    </div>
                    <div
                        className={classNames(classList['chat-body'], 'border-2 p-3 flex-grow-1', {
                            'border-primary border-bottom border-top':
                                !data.settings || !data.settings.primaryColor,
                        })}
                        ref={this.messagesEndRef}
                        style={chatBodyStyle}
                    >
                        {!messagesData || !messagesData.fetched ? (
                            <FontAwesomeIcon icon={faSpinner} spin={true} />
                        ) : (
                            <ul className="list-unstyled">{this.renderMessages()}</ul>
                        )}
                    </div>
                    <div className="p-2 d-flex align-items-center">
                        <div className={`${classList['chat-actions']} float-left mr-2`}>
                            <div className="d-flex align-items-center justify-content-between">
                                <button
                                    type="button"
                                    className="btn btn-plain line-height-1"
                                    onClick={this.toggleShowEmoji}
                                    disabled={!chatEnabled}
                                >
                                    <FontAwesomeIcon icon={faGrin} className="text-gray" />
                                </button>
                            </div>
                            {showEmojis && (
                                <div
                                    className={classList['emoji-picker']}
                                    ref={this.chatWrapperRef}
                                >
                                    <Picker onEmojiClick={this.onEmojiClick} />
                                </div>
                            )}
                        </div>
                        <form
                            className="d-flex align-items-center mt-1 mb-1 flex-grow-1"
                            onSubmit={this.handleSubmit}
                            noValidate={true}
                        >
                            <textarea
                                className={`${classList['textarea-chat']} form-control mr-sm-2 flex-grow-1`}
                                value={message}
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                                placeholder={chatEnabled ? 'Say Something' : 'Chat disabled'}
                                maxLength={510}
                                rows={1}
                                disabled={!chatEnabled}
                            />
                            <Button
                                type="submit"
                                className={`${classList['chat-submit']} btn-primary small`}
                                disabled={!User.isLoggedIn() || !chatEnabled}
                            >
                                Send
                            </Button>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

const Chat = connect(mapStateToProps, actions)(ChatComponent)

export default Chat
