import { faAngleDoubleDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import classList from './ClosedCaption.module.scss'
import classNames from 'classnames'

interface IProps {
    iframe: string
    textColorStyle?: any
    titleColor?: string
}

const ClosedCaption = ({ iframe, textColorStyle, titleColor }: IProps) => {
    const [show, setShow] = useState(false)

    const titleStyle: any = {
        color: titleColor || textColorStyle.color || '#ffffff',
    }

    return (
        <div className="mb-3">
            <button
                type="button"
                className="btn btn-plain d-flex align-items-center mx-auto mb-2"
                style={titleStyle}
                // tslint:disable-next-line jsx-no-lambda
                onClick={() => setShow(!show)}
            >
                <div className="mr-2 body-small">
                    {!show ? 'Display' : 'Hide'} Closed Captioning
                </div>
                <FontAwesomeIcon icon={!show ? faAngleDoubleDown : faTimes} size="xs" />
            </button>
            <div
                className={classNames(classList['caption-wrapper'], 'shadow', {
                    invisible: !show,
                    'h-0': !show,
                    'p-0': !show,
                })}
            >
                {ReactHtmlParser(iframe)}
            </div>
        </div>
    )
}

export default ClosedCaption
