import ElementAlignment from '../../enums/ElementAlignment'
import ContentfulMediaAsset from './ContentfulMediaAsset'

export default class Image {
    asset: ContentfulMediaAsset
    alignment: ElementAlignment
    mobileAlignment?: ElementAlignment
    width: string;
    maxWidth?: string
    borderWidth?: string;
    borderColor?: string;

    constructor(rawData: any) {
        const data = rawData || {}
        const image = data.image || {}
        this.asset = new ContentfulMediaAsset(image)
        this.alignment = data.alignment
        this.mobileAlignment = data.mobileAlignment || undefined
        this.width = data.width || 'auto'
        this.maxWidth = data.maxWidth
        this.borderWidth = data.borderWidth ? `${data.borderWidth}px` : undefined;
        this.borderColor = data.borderColor || undefined;
    }
}
