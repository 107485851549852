import { handleActions } from 'redux-actions'
import * as Immutable from 'immutable'
import { defaultFieldWithData, defaultFieldWithoutData, defaultReducerWithData } from './utils'
import User from '../../services/user'

const initialState = Immutable.fromJS({
    login: defaultFieldWithData,
    logout: defaultFieldWithoutData,
    register: defaultFieldWithData,
    user: undefined,
})

export default handleActions(
    {
        AUTH: {
            LOGIN: defaultReducerWithData('login'),
            LOGOUT: {
                FULFILLED: (state) => {
                    return state.set('user', null)
                },
            },
            REGISTER: defaultReducerWithData('register'),
            SET_USER: (state, { payload }) => {
                // TODO: Local storage set should probably go in actions somehow.
                localStorage.setItem(User.USER_KEY, JSON.stringify(payload))
                return state.set('user', Immutable.fromJS(payload))
            },
            CLEAR_ERRORS: (state) =>
                state
                    .update('login', (data) => data.set('error', null))
                    .update('register', (data) => data.set('error', null)),
        },
    },
    initialState
)
