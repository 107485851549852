import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { selectLogin, selectRegister, selectUser } from '../../redux/selectors/auth'
import { login, setUser, register, clearErrorsAuth } from '../../redux/actions/auth'
import './Signin.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button.component'
import { selectWebsite } from '../../redux/selectors/cms'
import Analytics from '../../providers/analytics'
import { IPropWithData } from '../../class/interfaces/redux'
import Website from '../../class/models/website'

enum VIEWS {
    LOGIN,
    GUEST,
}
interface IState {
    email: string
    code: string
    firstName: string
    lastName: string
    view?: VIEWS
    loginError?: string
    registerError?: string
}

interface IStateProps {
    loginData: any
    registerData: any
    userData: any
    websiteData: IPropWithData<Website>
}

const mapStateToProps = (state: any): IStateProps => {
    return {
        loginData: selectLogin(state),
        registerData: selectRegister(state),
        userData: selectUser(state),
        websiteData: selectWebsite(state),
    }
}

const actions = {
    login,
    setUser,
    register,
    clearErrorsAuth,
}

const connector = connect(mapStateToProps, actions)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux

/**
 * Call verify API to see if user exists and has roomId.
 * If no roomId, call Quenton's API to find a room the user can fit in.
 */

class SignInClass extends React.Component<IProps, IState> {
    inputRef: React.RefObject<HTMLInputElement> = React.createRef()

    constructor(props: IProps) {
        super(props)
        this.state = {
            email: '',
            code: '',
            firstName: '',
            lastName: '',
            view: VIEWS.LOGIN,
        }
    }

    componentDidMount() {
        if (this.inputRef.current) {
            this.inputRef.current.focus()
        }

        const settings = this.props.websiteData.data.settings

        if (settings && settings.login && settings.login.onlyShowGuestLogin) {
            this.setView(VIEWS.GUEST)
        }
    }

    componentDidUpdate(prevProps: any) {
        this.checkLogin(prevProps)
        this.checkRegister(prevProps)
    }

    checkLogin(prevProps: any) {
        const {
            loginData: { fetched: prevFetched },
        } = prevProps
        const {
            loginData: { fetched: currFetched, data, error: currError },
        } = this.props

        if (currError) {
            if (currError.message === 'USER_NOT_FOUND') {
                this.setState({ loginError: 'User not found' })
                Analytics.track('User not found', { data, email: this.state.email, currError })
                this.props.clearErrorsAuth()
            } else {
                this.setState({ loginError: 'Error logging in' })
                this.props.clearErrorsAuth()
            }
        }

        if (!prevFetched && currFetched && !currError) {
            Analytics.track('User found', { ...data, email: this.state.email })
            this.props.setUser({ ...data, email: this.state.email })
        }
    }

    checkRegister(prevProps: any) {
        const {
            registerData: { fetched: prevFetched },
        } = prevProps
        const {
            registerData: { fetched: currFetched, data, error: currError },
        } = this.props

        if (currError) {
            Analytics.track('Check Register Error', { ...currError })
            this.setState({ registerError: 'Error registering user.' })
            console.error(currError)
            this.props.clearErrorsAuth()
        }

        if (!prevFetched && currFetched && !currError) {
            Analytics.track(`User Registered`, { ...data, email: this.state.email })
            this.props.setUser({ ...data, email: this.state.email })
        }
    }

    handleSubmitLogin = (e: any) => {
        e.preventDefault()
        const { email, code } = this.state
        this.setState({
            loginError: undefined,
        })
        Analytics.track(`Login Submit`, { email, code })
        this.props.login({ email, code })
    }

    handleRegisterSubmit = (e: any) => {
        e.preventDefault()
        const { email, firstName, lastName } = this.state
        this.setState({
            registerError: undefined,
        })
        Analytics.track('Registration Submit', { email, firstName, lastName })
        this.props.register({
            email,
            firstName,
            lastName,
        })
    }

    backToLogin = () => {
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            view: VIEWS.LOGIN,
        })
    }

    onChangeEmail = (e: any) => {
        this.setState({ email: e.target.value })
    }

    onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ code: e.target.value })
    }

    onChangeFirstName = (e: any) => {
        this.setState({
            firstName: e.target.value,
        })
    }

    onChangeLastName = (e: any) => {
        this.setState({
            lastName: e.target.value,
        })
    }

    setView = (view: VIEWS) => {
        this.setState({
            view,
            loginError: undefined,
        })
    }

    disableSubmit = () => {
        const {
            websiteData: { data },
            registerData: { fetching },
        } = this.props
        const { email, firstName, lastName } = this.state

        const login = data.settings && data.settings.login ? data.settings.login : undefined

        if (login) {
            return (
                (login.registrationFirstNameRequired && !firstName) ||
                (login.registrationLastNameRequired && !lastName) ||
                (login.registrationEmailRequired && !email) ||
                fetching
            )
        }
        return false
    }

    generatePlaceholderText = (title: string, isRequired: boolean) => {
        return `${title} (${isRequired ? 'required' : 'optional'})`
    }

    render() {
        const { email, code, firstName, lastName, view, loginError, registerError } = this.state
        const {
            websiteData: { data },
            loginData,
            registerData,
        } = this.props

        const settings = data ? data.settings : undefined

        const textStyle: any = {
            color: settings && settings.secondaryColor ? settings.secondaryColor : '#ffffff',
        }

        if (!settings || !settings.login) {
            return null
        }

        const inputStyles: any = {
            backgroundColor: settings.login.inputBackgroundColor || undefined,
            color: settings.login.inputTextColor || undefined,
        }

        const requireLogin = settings.login.requireLogin

        return (
            <>
                {view === undefined && (
                    <div className="d-flex align-items-center justify-content-center">
                        <Button
                            className="btn-primary mr-3"
                            // tslint:disable-next-line jsx-no-lambda
                            onClick={() => this.setView(VIEWS.LOGIN)}
                        >
                            Login
                        </Button>
                        {/* tslint:disable-next-line jsx-no-lambda */}
                        <Button className="btn-primary" onClick={() => this.setView(VIEWS.GUEST)}>
                            Continue as Guest
                        </Button>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        {view === VIEWS.LOGIN && (
                            <>
                                {/* <p className="text-center" style={textStyle}>
                                    Enter your email address to join the event.
                                </p> */}
                                <div className="mb-3">
                                    <form onSubmit={this.handleSubmitLogin}>
                                        <div className="form-group">
                                            <input
                                                name="email"
                                                id="email"
                                                ref={this.inputRef}
                                                type="text"
                                                className="form-control mr-2 w-100"
                                                placeholder="Email Address"
                                                autoComplete="off"
                                                value={email}
                                                onChange={this.onChangeEmail}
                                                style={inputStyles}
                                            />
                                        </div>
                                        {requireLogin && (
                                            <div className="form-group">
                                                <input
                                                    name="code"
                                                    id="code"
                                                    ref={this.inputRef}
                                                    type="text"
                                                    className="form-control mr-2 w-100"
                                                    placeholder="Event Code"
                                                    autoComplete="off"
                                                    value={code}
                                                    onChange={this.onChangeCode}
                                                    style={inputStyles}
                                                />
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                type="submit"
                                                className="btn btn-primary px-5 rounded"
                                                disabled={!email || loginData.fetching}
                                            >
                                                <span className="mr-2">
                                                    Submit{loginData.fetching && 'ting'}
                                                </span>
                                                {loginData.fetching && (
                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                )}
                                            </Button>
                                        </div>
                                    </form>
                                    {!!loginError && (
                                        <div className="text-danger small mt-2">{loginError}</div>
                                    )}
                                    {settings.login.allowWalkIns && (
                                        <div className="mt-3 text-center">
                                            Not registered?{' '}
                                            <button
                                                type="button"
                                                onClick={() => this.setView(VIEWS.GUEST)}
                                                className="btn btn-link p-0"
                                            >
                                                Continue as Guest
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {view === VIEWS.GUEST && (
                            <>
                                {/* <p className="text-center" style={textStyle}>
                                    Continue as guest.
                                </p> */}
                                <form onSubmit={this.handleRegisterSubmit}>
                                    {settings.login.registrationFirstName && (
                                        <div className="form-group">
                                            <input
                                                name="firstName"
                                                id="firstName"
                                                type="text"
                                                className="form-control"
                                                placeholder={this.generatePlaceholderText(
                                                    'First Name',
                                                    settings.login.registrationFirstNameRequired
                                                )}
                                                autoComplete="off"
                                                value={firstName}
                                                required={
                                                    settings.login.registrationFirstNameRequired
                                                }
                                                onChange={this.onChangeFirstName}
                                                style={inputStyles}
                                            />
                                        </div>
                                    )}
                                    {settings.login.registrationLastName && (
                                        <div className="form-group">
                                            <input
                                                name="lastName"
                                                id="lastName"
                                                type="text"
                                                className="form-control"
                                                placeholder={this.generatePlaceholderText(
                                                    'Last Name',
                                                    settings.login.registrationLastNameRequired
                                                )}
                                                autoComplete="off"
                                                value={lastName}
                                                required={
                                                    settings.login.registrationLastNameRequired
                                                }
                                                onChange={this.onChangeLastName}
                                                style={inputStyles}
                                            />
                                        </div>
                                    )}
                                    {settings.login.registrationEmail && (
                                        <div className="form-group">
                                            <input
                                                name="email"
                                                id="email"
                                                type="text"
                                                className="form-control"
                                                placeholder={this.generatePlaceholderText(
                                                    'Email Address',
                                                    settings.login.registrationEmailRequired
                                                )}
                                                autoComplete="off"
                                                value={email}
                                                required={settings.login.registrationEmailRequired}
                                                onChange={this.onChangeEmail}
                                                style={inputStyles}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={`d-flex align-items-center justify-content-${
                                            settings.login.onlyShowGuestLogin ? 'end' : 'between'
                                        }`}
                                    >
                                        {!settings.login.onlyShowGuestLogin && (
                                            <button
                                                type="button"
                                                className="btn btn-plain"
                                                style={textStyle}
                                                onClick={this.backToLogin}
                                            >
                                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                                            </button>
                                        )}
                                        <Button
                                            type="submit"
                                            className="btn btn-primary px-5 rounded"
                                            disabled={this.disableSubmit()}
                                            secondary
                                        >
                                            <span className="mr-2">
                                                Submit{registerData.fetching && 'ting'}
                                            </span>
                                            {registerData.fetching && (
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                            )}
                                        </Button>
                                    </div>
                                </form>
                                {!!registerError && (
                                    <div className="text-danger small mt-2">{registerError}</div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

const SignIn = connect(mapStateToProps, actions)(SignInClass)

export default SignIn
