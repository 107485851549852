import Axios from 'axios'

export const instanceApi = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const instanceChatApi = Axios.create({
    baseURL: process.env.REACT_APP_CHAT_API_URL,
})

export const instanceAuth = Axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,
})

export const instanceQuizzes = Axios.create({
    baseURL: process.env.REACT_APP_QUIZZES_URL,
})

export const instanceViewerStats = Axios.create({
    baseURL: process.env.REACT_APP_VIEWER_STATS_API,
})

instanceApi.interceptors.request.use((config) => {
    return config
})

instanceApi.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

instanceAuth.interceptors.request.use((config) => {
    config.headers['x-api-key'] = process.env.REACT_APP_AUTH_URL_API_KEY
    return config
})

instanceAuth.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

instanceQuizzes.interceptors.request.use((config) => {
    config.headers['x-api-key'] = process.env.REACT_APP_QUIZZES_URL_API_KEY
    return config
})
