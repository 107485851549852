import React from 'react'
import ReactMarkdown from 'react-markdown'
import { default as CustomContentData } from '../../class/models/custom-content'
import { CustomContentTemplates } from '../../enums/TemplateTypes'
import styles from './CustomContent.module.scss'

interface ICustomContent {
    data: CustomContentData
}

const CustomContent = ({ data }: ICustomContent) => {
    if (data.template === CustomContentTemplates.SimpleTable) {
        return renderSimpleTable(data.data)
    } else {
        return null
    }
}

export default CustomContent

interface ISimpleTableRow {
    title: string
    text: string
}

const renderSimpleTable = (data: ISimpleTableRow[]) => {
    return (
        <table className={`${styles.table} table`}>
            <tbody>
                {data.map((rowData: ISimpleTableRow, index: number) => (
                    <tr key={index}>
                        <th className="text-right">{rowData.title}</th>
                        <td className="text-left pl-3">
                            <ReactMarkdown children={rowData.text} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
