/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAttendeeConversations = /* GraphQL */ `
  mutation CreateAttendeeConversations($conversationId: ID!, $userId: ID!) {
    createAttendeeConversations(
      conversationId: $conversationId
      userId: $userId
    ) {
      associated {
        attendeeId
        conversationId
      }
      attendee {
        code
        email
        eventId
        eventInstanceId
        firstName
        hostId
        isHost
        lastName
        roomId
        userId
      }
      attendeeId
      conversation {
        createdAt
        id
        name
      }
      conversationId
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation($createdAt: String, $id: ID!, $name: String!) {
    createConversation(createdAt: $createdAt, id: $id, name: $name) {
      createdAt
      id
      messages {
        nextToken
      }
      name
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $cmsId: String
    $eventName: String!
    $eventTypeId: Int!
    $orgId: ID!
  ) {
    createEvent(
      cmsId: $cmsId
      eventName: $eventName
      eventTypeId: $eventTypeId
      orgId: $orgId
    ) {
      error {
        exists
        message
      }
      eventCreateDate
      eventId
      eventInstances {
        cmsId
        eventDate
        eventDateEndTime
        eventDateStartTime
        eventId
        eventInstanceId
        eventRoomCount
        eventInstanceName
      }
      eventName
      eventTypeId
      orgId
      cmsId
    }
  }
`;
export const createEventInstance = /* GraphQL */ `
  mutation CreateEventInstance(
    $eventDateEndTime: AWSDateTime!
    $eventId: ID!
    $cmsId: String
    $eventDate: AWSDateTime!
    $eventDateStartTime: AWSDateTime!
    $eventInstanceName: String!
  ) {
    createEventInstance(
      eventDateEndTime: $eventDateEndTime
      eventId: $eventId
      cmsId: $cmsId
      eventDate: $eventDate
      eventDateStartTime: $eventDateStartTime
      eventInstanceName: $eventInstanceName
    ) {
      cmsId
      error {
        exists
        message
      }
      eventDate
      eventDateEndTime
      eventDateStartTime
      eventId
      eventInstanceId
      eventRoomCount
      eventInstanceName
      sponsors {
        sponsorId
        sponsorLogoUrl
        sponsorName
        sponsorUrl
        sponsorOrgId
        sponsorCreatedDate
        sponsorUpdatedDate
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $content: String
    $conversationId: ID!
    $createdAt: String!
    $id: ID!
  ) {
    createMessage(
      content: $content
      conversationId: $conversationId
      createdAt: $createdAt
      id: $id
    ) {
      content
      conversationId
      createdAt
      creator {
        code
        email
        eventId
        eventInstanceId
        firstName
        hostId
        isHost
        lastName
        roomId
        userId
      }
      id
      isSent
      recipient {
        code
        email
        eventId
        eventInstanceId
        firstName
        hostId
        isHost
        lastName
        roomId
        userId
      }
      sender
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($orgName: String!, $orgTypeId: Int!) {
    createOrganization(orgName: $orgName, orgTypeId: $orgTypeId) {
      error {
        exists
        message
      }
      orgCreateDate
      orgId
      orgName
      orgTypeId
      events {
        eventCreateDate
        eventId
        eventName
        eventTypeId
        orgId
        cmsId
      }
      groupId
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $firstName: String!
    $identifier: String
    $lastName: String!
    $orgId: ID!
    $personId: ID!
    $position: String
  ) {
    createPerson(
      firstName: $firstName
      identifier: $identifier
      lastName: $lastName
      orgId: $orgId
      personId: $personId
      position: $position
    ) {
      firstName
      identifier
      lastName
      orgId
      orgName
      personId
      position
    }
  }
`;
export const createRegistration = /* GraphQL */ `
  mutation CreateRegistration(
    $email: String!
    $eventInstanceId: ID!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
  ) {
    createRegistration(
      email: $email
      eventInstanceId: $eventInstanceId
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
    ) {
      code
      email
      error {
        exists
        message
      }
      eventId
      eventInstanceId
      firstName
      hostId
      isHost
      lastName
      roomId
      userId
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent($eventId: ID!) {
    deleteEvent(eventId: $eventId)
  }
`;
export const deleteEventInstance = /* GraphQL */ `
  mutation DeleteEventInstance($eventInstanceId: ID!) {
    deleteEventInstance(eventInstanceId: $eventInstanceId)
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($orgId: String!) {
    deleteOrganization(orgId: $orgId)
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson($personId: String!) {
    deletePerson(personId: $personId) {
      firstName
      identifier
      lastName
      orgId
      orgName
      personId
      position
    }
  }
`;
export const endEvent = /* GraphQL */ `
  mutation EndEvent($eventId: String) {
    endEvent(eventId: $eventId)
  }
`;
export const endLiveeventMeeting = /* GraphQL */ `
  mutation EndLiveeventMeeting($roomId: String) {
    endLiveeventMeeting(roomId: $roomId)
  }
`;
export const joinLiveeventMeeting = /* GraphQL */ `
  mutation JoinLiveeventMeeting($roomId: String!, $userId: String!) {
    joinLiveeventMeeting(roomId: $roomId, userId: $userId) {
      attendeeInfo
      error {
        exists
        message
      }
      meetingInfo
      roomId
    }
  }
`;
export const putEmoji = /* GraphQL */ `
  mutation PutEmoji(
    $attendeeId: ID
    $email: String!
    $emoji: String!
    $eventId: String!
    $firstName: String!
    $lastName: String!
  ) {
    putEmoji(
      attendeeId: $attendeeId
      email: $email
      emoji: $emoji
      eventId: $eventId
      firstName: $firstName
      lastName: $lastName
    ) {
      attendeeId
      email
      emoji
      emojiEventId
      eventId
      firstName
      lastName
      timeStamp
    }
  }
`;
export const putEventQuestion = /* GraphQL */ `
  mutation PutEventQuestion(
    $eventId: ID!
    $questionId: ID
    $question: String!
    $answers: [AnswerInput]
    $answerKey: String
  ) {
    putEventQuestion(
      eventId: $eventId
      questionId: $questionId
      question: $question
      answers: $answers
      answerKey: $answerKey
    ) {
      eventId
      questionId
      question
      answers {
        answerId
        quizId
        answer
        userId
      }
      answerKey
      timer
    }
  }
`;
export const putEventQuiz = /* GraphQL */ `
  mutation PutEventQuiz(
    $eventId: ID!
    $quizId: ID
    $question: String!
    $answers: [AnswerInput]
    $answerKey: String
    $timer: Int
  ) {
    putEventQuiz(
      eventId: $eventId
      quizId: $quizId
      question: $question
      answers: $answers
      answerKey: $answerKey
      timer: $timer
    ) {
      eventId
      quizId
      question
      answers {
        answerId
        quizId
        answer
        userId
      }
      answerKey
      timer
      expiredTimer
    }
  }
`;
export const putPledge = /* GraphQL */ `
  mutation PutPledge(
    $eventInstanceId: ID!
    $userId: ID!
    $username: String
    $pledgeAmount: Int!
    $comment: String
  ) {
    putPledge(
      eventInstanceId: $eventInstanceId
      userId: $userId
      username: $username
      pledgeAmount: $pledgeAmount
      comment: $comment
    ) {
      userId
      username
      pledgeAmount
      pledgeId
      comment
      eventInstanceId
      pledgeTimeStamp
    }
  }
`;
export const putEventInstance = /* GraphQL */ `
  mutation PutEventInstance($eventInstanceId: String!) {
    putEventInstance(eventInstanceId: $eventInstanceId) {
      cmsId
      error {
        exists
        message
      }
      eventDate
      eventDateEndTime
      eventDateStartTime
      eventId
      eventInstanceId
      eventRoomCount
      eventInstanceName
      sponsors {
        sponsorId
        sponsorLogoUrl
        sponsorName
        sponsorUrl
        sponsorOrgId
        sponsorCreatedDate
        sponsorUpdatedDate
      }
    }
  }
`;
export const putMeetingAttendee = /* GraphQL */ `
  mutation PutMeetingAttendee(
    $eventId: String
    $meetingId: String
    $userId: String
  ) {
    putMeetingAttendee(
      eventId: $eventId
      meetingId: $meetingId
      userId: $userId
    ) {
      code
      email
      error {
        exists
        message
      }
      eventId
      eventInstanceId
      firstName
      hostId
      isHost
      lastName
      roomId
      userId
    }
  }
`;
export const putMessage = /* GraphQL */ `
  mutation PutMessage($eventId: ID!, $message: String!) {
    putMessage(eventId: $eventId, message: $message) {
      eventId
      message
      messageDateTime
      messageId
    }
  }
`;
export const putRecordMeeting = /* GraphQL */ `
  mutation PutRecordMeeting(
    $eventId: String
    $isRecording: Boolean
    $meetingId: String
  ) {
    putRecordMeeting(
      eventId: $eventId
      isRecording: $isRecording
      meetingId: $meetingId
    ) {
      eventId
      isRecording
      meetingId
    }
  }
`;
export const putScore = /* GraphQL */ `
  mutation PutScore(
    $EventDate: AWSDate
    $eventId: String
    $home: String
    $homeScore: Int
    $visitor: String
    $visitorScore: Int
  ) {
    putScore(
      EventDate: $EventDate
      eventId: $eventId
      home: $home
      homeScore: $homeScore
      visitor: $visitor
      visitorScore: $visitorScore
    ) {
      EventDate
      eventId
      home
      homeScore
      visitor
      visitorScore
    }
  }
`;
export const putVideoAction = /* GraphQL */ `
  mutation PutVideoAction(
    $action: VideoAction!
    $actionDateTime: AWSDateTime!
    $eventId: String!
    $host: String!
    $optionalValue: String
  ) {
    putVideoAction(
      action: $action
      actionDateTime: $actionDateTime
      eventId: $eventId
      host: $host
      optionalValue: $optionalValue
    ) {
      action
      actionDateTime
      eventId
      host
      optionalValue
      optionalValueType
    }
  }
`;
export const registerWalkIn = /* GraphQL */ `
  mutation RegisterWalkIn(
    $cmsId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    registerWalkIn(
      cmsId: $cmsId
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      code
      email
      error {
        exists
        message
      }
      eventId
      eventInstanceId
      firstName
      hostId
      isHost
      lastName
      roomId
      userId
    }
  }
`;
export const toggleMainVideoSound = /* GraphQL */ `
  mutation ToggleMainVideoSound($onOff: Boolean) {
    toggleMainVideoSound(onOff: $onOff)
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $eventId: ID!
    $eventName: String
    $eventTypeId: Int
    $cmsId: String
  ) {
    updateEvent(
      eventId: $eventId
      eventName: $eventName
      eventTypeId: $eventTypeId
      cmsId: $cmsId
    ) {
      error {
        exists
        message
      }
      eventCreateDate
      eventId
      eventInstances {
        cmsId
        eventDate
        eventDateEndTime
        eventDateStartTime
        eventId
        eventInstanceId
        eventRoomCount
        eventInstanceName
      }
      eventName
      eventTypeId
      orgId
      cmsId
    }
  }
`;
export const updateEventInstance = /* GraphQL */ `
  mutation UpdateEventInstance(
    $eventInstanceId: ID!
    $eventDate: AWSDateTime
    $eventDateStartTime: AWSDateTime
    $eventDateEndTime: AWSDateTime
    $cmsId: String
    $eventInstanceName: String
  ) {
    updateEventInstance(
      eventInstanceId: $eventInstanceId
      eventDate: $eventDate
      eventDateStartTime: $eventDateStartTime
      eventDateEndTime: $eventDateEndTime
      cmsId: $cmsId
      eventInstanceName: $eventInstanceName
    ) {
      cmsId
      error {
        exists
        message
      }
      eventDate
      eventDateEndTime
      eventDateStartTime
      eventId
      eventInstanceId
      eventRoomCount
      eventInstanceName
      sponsors {
        sponsorId
        sponsorLogoUrl
        sponsorName
        sponsorUrl
        sponsorOrgId
        sponsorCreatedDate
        sponsorUpdatedDate
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $orgId: String!
    $orgName: String
    $orgTypeId: String
    $groupId: ID
  ) {
    updateOrganization(
      orgId: $orgId
      orgName: $orgName
      orgTypeId: $orgTypeId
      groupId: $groupId
    ) {
      error {
        exists
        message
      }
      orgCreateDate
      orgId
      orgName
      orgTypeId
      events {
        eventCreateDate
        eventId
        eventName
        eventTypeId
        orgId
        cmsId
      }
      groupId
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $firstName: String!
    $identifier: String
    $lastName: String!
    $orgId: ID
    $personId: ID!
    $position: String
  ) {
    updatePerson(
      firstName: $firstName
      identifier: $identifier
      lastName: $lastName
      orgId: $orgId
      personId: $personId
      position: $position
    ) {
      firstName
      identifier
      lastName
      orgId
      orgName
      personId
      position
    }
  }
`;
export const showPoll = /* GraphQL */ `
  mutation ShowPoll(
    $eventId: ID
    $question: String!
    $answers: [AnswerInput]!
    $pollId: ID!
    $timer: Int
  ) {
    showPoll(
      eventId: $eventId
      question: $question
      answers: $answers
      pollId: $pollId
      timer: $timer
    ) {
      pollId
      eventId
      question
      answers {
        answerId
        quizId
        answer
        userId
      }
      timer
    }
  }
`;
export const showQuiz = /* GraphQL */ `
  mutation ShowQuiz(
    $eventId: ID!
    $question: String!
    $answers: [AnswerInput]!
    $quizId: String!
    $timer: Int
    $answerKey: String!
    $expiredTimer: Int
  ) {
    showQuiz(
      eventId: $eventId
      question: $question
      answers: $answers
      quizId: $quizId
      timer: $timer
      answerKey: $answerKey
      expiredTimer: $expiredTimer
    ) {
      eventId
      quizId
      question
      answers {
        answerId
        quizId
        answer
        userId
      }
      answerKey
      timer
      expiredTimer
    }
  }
`;
export const showEventQuiz = /* GraphQL */ `
  mutation ShowEventQuiz($eventId: ID) {
    showEventQuiz(eventId: $eventId)
  }
`;
export const showQuestion = /* GraphQL */ `
  mutation ShowQuestion(
    $eventId: String
    $question: String!
    $questionId: ID!
    $answers: [String!]!
    $answerKey: String
  ) {
    showQuestion(
      eventId: $eventId
      question: $question
      questionId: $questionId
      answers: $answers
      answerKey: $answerKey
    ) {
      eventId
      questionId
      question
      answers {
        answerId
        quizId
        answer
        userId
      }
      answerKey
      timer
    }
  }
`;
export const sendUserAnswer = /* GraphQL */ `
  mutation SendUserAnswer(
    $answerKey: String!
    $questionId: ID!
    $userId: ID!
    $userAnswer: String!
    $isCorrect: Boolean!
    $eventId: String!
  ) {
    sendUserAnswer(
      answerKey: $answerKey
      questionId: $questionId
      userId: $userId
      userAnswer: $userAnswer
      isCorrect: $isCorrect
      eventId: $eventId
    ) {
      answerId
      quizId
      answer
      userId
      user {
        id
        firstName
        lastName
        email
        emailVerified
      }
    }
  }
`;
export const sendPollAnswer = /* GraphQL */ `
  mutation SendPollAnswer(
    $pollId: ID!
    $eventId: String!
    $userId: ID!
    $userAnswer: String!
  ) {
    sendPollAnswer(
      pollId: $pollId
      eventId: $eventId
      userId: $userId
      userAnswer: $userAnswer
    ) {
      quizId
      answers {
        count
        answer
      }
    }
  }
`;
export const sendUserAnswerAggregates = /* GraphQL */ `
  mutation SendUserAnswerAggregates(
    $eventId: ID!
    $quizId: ID!
    $answers: [AnswersInput]
  ) {
    sendUserAnswerAggregates(
      eventId: $eventId
      quizId: $quizId
      answers: $answers
    ) {
      quizId
      answers {
        count
        answer
      }
    }
  }
`;
export const createSponsor = /* GraphQL */ `
  mutation CreateSponsor(
    $sponsorName: String!
    $sponsorOrgId: ID!
    $sponsorLogoUrl: String
    $sponsorUrl: String
  ) {
    createSponsor(
      sponsorName: $sponsorName
      sponsorOrgId: $sponsorOrgId
      sponsorLogoUrl: $sponsorLogoUrl
      sponsorUrl: $sponsorUrl
    ) {
      sponsorId
      sponsorLogoUrl
      sponsorName
      sponsorUrl
      sponsorOrgId
      sponsorCreatedDate
      sponsorUpdatedDate
    }
  }
`;
export const deleteSponsor = /* GraphQL */ `
  mutation DeleteSponsor($sponsorId: ID!) {
    deleteSponsor(sponsorId: $sponsorId)
  }
`;
export const updateSponsor = /* GraphQL */ `
  mutation UpdateSponsor(
    $sponsorId: ID!
    $sponsorName: String
    $sponsorLogoUrl: String
    $sponsorUrl: String
  ) {
    updateSponsor(
      sponsorId: $sponsorId
      sponsorName: $sponsorName
      sponsorLogoUrl: $sponsorLogoUrl
      sponsorUrl: $sponsorUrl
    ) {
      sponsorId
      sponsorLogoUrl
      sponsorName
      sponsorUrl
      sponsorOrgId
      sponsorCreatedDate
      sponsorUpdatedDate
    }
  }
`;
export const createEventInstanceSponsor = /* GraphQL */ `
  mutation CreateEventInstanceSponsor($eventInstanceId: ID!, $sponsorId: ID!) {
    createEventInstanceSponsor(
      eventInstanceId: $eventInstanceId
      sponsorId: $sponsorId
    ) {
      eventInstanceId
      sponsorId
    }
  }
`;
export const deleteEventInstanceSponsor = /* GraphQL */ `
  mutation DeleteEventInstanceSponsor($eventInstanceId: ID!, $sponsorId: ID!) {
    deleteEventInstanceSponsor(
      eventInstanceId: $eventInstanceId
      sponsorId: $sponsorId
    ) {
      eventInstanceId
      sponsorId
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($name: String!) {
    createGroup(name: $name) {
      ... on Group {
        id
        name
        organizations {
          orgCreateDate
          orgId
          orgName
          orgTypeId
          groupId
        }
      }
      ... on Error {
        exists
        message
      }
    }
  }
`;
export const sendTopicInterest = /* GraphQL */ `
  mutation SendTopicInterest($input: TopicInterestInput!) {
    sendTopicInterest(input: $input) {
      ... on TopicInterest {
        id
        eventInstanceId
        userId
        userName
        topic
        videoTimestamp
      }
      ... on Error {
        exists
        message
      }
    }
  }
`;
