import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { connect } from 'react-redux'
import Section from '../../class/models/section'
import HeroSectionComponent from '../../containers/HeroSectionContainer/HeroSectionContainer.component'
import SectionContent from '../../components/Section/Section'
import { selectWebsite } from '../../redux/selectors/cms'
import Analytics from '../../providers/analytics'
import { selectUser } from '../../redux/selectors/auth'

const mapStateToProps: any = (state: any) => {
    return {
        websiteData: selectWebsite(state),
        userData: selectUser(state),
    }
}

class HomeComponent extends React.Component<any> {
    /**
     * Create components for each of the data types coming back from Contentful.
     * https://nansen.com/blog/contentful/dynamic-content-types-with-gatsby--contentful/ use this as inspiration for dynamic components.
     */

    componentDidMount = () => {
        const { userData } = this.props

        if (userData && userData.emailId) {
            Analytics.identify(userData.emailId, userData)
        }
        Analytics.track('Website Visited', userData)
    }

    render() {
        const {
            websiteData: { fetched, data },
        } = this.props
        return !fetched ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <FontAwesomeIcon icon={faSpinner} spin={true} size="3x" />
            </div>
        ) : (
            <>
                <HeroSectionComponent />
                {data.sections.map((content: Section, index: number) => (
                    <SectionContent key={index} data={content} />
                ))}
            </>
        )
    }
}

const Home = connect(mapStateToProps)(HomeComponent)
export default Home
