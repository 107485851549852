export default class LoginModel {
    registrationFirstName: boolean
    registrationFirstNameRequired: boolean
    registrationLastName: boolean
    registrationLastNameRequired: boolean
    registrationEmail: boolean
    registrationEmailRequired: boolean
    inputBackgroundColor?: string
    inputTextColor?: string
    allowWalkIns: boolean
    requireLogin: boolean
    onlyShowGuestLogin: boolean

    constructor(rawData: any) {
        const data = rawData || {}
        this.registrationFirstName = !!data.registrationFirstName
        this.registrationFirstNameRequired = !!data.registrationFirstNameRequired
        this.registrationLastName = !!data.registrationLastName
        this.registrationLastNameRequired = !!data.registrationLastNameRequired
        this.registrationEmail = !!data.registrationEmail
        this.registrationEmailRequired = !!data.registrationEmailRequired
        this.inputBackgroundColor = data.inputBackgroundColor || undefined
        this.inputTextColor = data.inputTextColor || undefined
        this.allowWalkIns = !!data.allowWalkIns
        this.requireLogin = !!data.requireLogin
        this.onlyShowGuestLogin = !!data.onlyShowGuestLogin
    }
}
