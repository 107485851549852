import React from 'react'
import Prize from '../../class/models/prize'
import CustomImage from '../CustomImage/CustomImage'

interface IPrizeSection {
    data: any
}

const PrizeSection = ({ data: { prizes } }: IPrizeSection) => {
    return prizes
        ? prizes.map((prize: Prize, index: number) => (
              <div key={index} className="text-center mb-7">
                  <h4 className="text-primary mb-2">{prize.title}</h4>
                  <p>{prize.description}</p>
                  {prize.image && <CustomImage data={prize.image} />}
              </div>
          ))
        : null
}

export default PrizeSection
