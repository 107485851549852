import React from 'react'
import Image from '../../class/models/image'
import ImageGroup from '../../class/models/image-group'
import CustomImage from '../CustomImage/CustomImage'
import styles from './CustomImageGroup.module.scss'
import classNames from 'classnames'

interface ICustomImageGroup {
    data: ImageGroup
}

const CustomImageGroup = ({ data }: ICustomImageGroup) => {
    // TODO: This logic should be a little bit more dynamic. Specific to United Way.
    const isOneThirdWithNoSpacing = data.orientation === '1/3' && data.noSpacing
    return (
        <div className={classNames({ row: !isOneThirdWithNoSpacing })}>
            <div className={classNames({ 'col-md-10 offset-md-1 mb-0': !isOneThirdWithNoSpacing })}>
                <div className={classNames('row', { 'no-gutters': data.noSpacing })}>
                    {data.images.map((image: Image, index: number) => (
                        <div
                            className={classNames('col-md', { 'mb-0': isOneThirdWithNoSpacing })}
                            key={index}
                        >
                            <div className={styles['image-wrapper']}>
                                <CustomImage data={image} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CustomImageGroup
