import React from 'react'
import ReactMarkdown from 'react-markdown'
import VideoEmbed from '../../class/models/video-embed'
import Iframe from '../Iframe/Iframe.component'

interface IProps {
    data: VideoEmbed
}

export default class VideoEmbedComponent extends React.Component<IProps> {
    render() {
        const {
            data: { copy, link, allow, modal, height },
        } = this.props

        return (
            <div>
                {!!copy && (
                    <div className="text-center">
                        <ReactMarkdown children={copy} allowDangerousHtml={true} />
                    </div>
                )}
                <Iframe src={link} modal={modal} allow={allow} height={height} />
            </div>
        )
    }
}
