import React from 'react'
import ReactMarkdownWithHtml from 'react-markdown'
import classNames from 'classnames'
import Speaker from '../../../class/models/speaker'
import SpeakerGroup from '../../../class/models/speaker-group'
import CustomImage from '../../CustomImage/CustomImage'
import CustomTitle from '../../CustomTitle/CustomTitle'

interface ISpeakersSection {
    data: any
}

const SpeakersSectionTemplateTwo = ({ data: { speakers, speakerGroups } }: ISpeakersSection) => {
    const imageStyle: any = {
        borderRadius: '50%',
    }
    return speakerGroups
        ? speakerGroups.map((speakerGroup: SpeakerGroup, index: number) => (
              <div key={index} className="mb-5">
                  {(speakerGroup.title || speakerGroup.groupTitle) && (
                      <div className="mb-5">
                          {speakerGroup.groupTitle ? (
                              <CustomTitle data={speakerGroup.groupTitle} />
                          ) : (
                              <>
                                  {speakerGroup.title && (
                                      <h4 className="mb-0 text-uppercase font-weight-normal">
                                          {speakerGroup.title}
                                      </h4>
                                  )}
                              </>
                          )}
                      </div>
                  )}
                  {speakerGroup.speakers && (
                      <div className="row justify-content-center">
                          {speakerGroup.speakers.map((speaker: Speaker, index: number) => (
                              <div
                                  className={classNames({
                                      'col-md-4':
                                          speakerGroup.speakers && speakerGroup.speakers.length > 2,
                                      'col-md-6':
                                          speakerGroup.speakers &&
                                          speakerGroup.speakers.length <= 2,
                                  })}
                                  key={index}
                              >
                                  <div className="h-100 text-center">
                                      {speaker.image && (
                                          <div className="mb-3">
                                              <CustomImage
                                                  data={speaker.image}
                                                  imageStyle={imageStyle}
                                              />
                                          </div>
                                      )}
                                      <div className="text-center">
                                          <div className="font-weight-bold mb-3">
                                              {speaker.name}
                                          </div>
                                          {speaker.title && (
                                              <div className="body-small">
                                                  <ReactMarkdownWithHtml
                                                      allowDangerousHtml={true}
                                                      children={speaker.title}
                                                  />
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          ))}
                      </div>
                  )}
              </div>
          ))
        : null
}

export default SpeakersSectionTemplateTwo
