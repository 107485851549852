import {createSelector} from 'reselect';

import {toJSSelector} from './utils';

const selectAuthI = (state) => state.auth;

export const selectLogin = toJSSelector(
    createSelector(
        [selectAuthI],
        (auth) => {
            return auth.get('login')
        },
    ),
);

export const selectLogout = toJSSelector(
    createSelector(
        [selectAuthI],
        (auth) => {
            return auth.get('logout')
        },
    ),
);

export const selectRegister = toJSSelector(
    createSelector(
        [selectAuthI],
        (auth) => {
            return auth.get('register')
        },
    ),
);

export const selectUser = toJSSelector(
    createSelector(
        [selectAuthI],
        (auth) => {
            return auth.get('user')
        },
    ),
);
