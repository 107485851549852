import {createSelector} from 'reselect';

import {toJSSelector} from './utils';

const selectQuizzesI = (state) => state.quizzes;

export const selectQuizzes = toJSSelector(
    createSelector(
        [selectQuizzesI],
        (quizzes) => {
            return quizzes.get('quizzes')
        },
    ),
);

export const selectPutQuizzAnswer = toJSSelector(
    createSelector(
        [selectQuizzesI],
        (quizzes) => {
            return quizzes.get('putQuizAnswer')
        },
    ),
);
