import React from 'react'
import Sponsor from '../../../class/models/sponsor'
import SponsorGroup from '../../../class/models/sponsor-group'
import CustomImage from '../../CustomImage/CustomImage'
import CustomTitle from '../../CustomTitle/CustomTitle'
import classList from './SponsorsSectionTemplateOne.module.scss'

interface ISponsorsSection {
    data: any
}

const SponsorsSectionTemplateOne = ({ data: { sponsorGroups } }: ISponsorsSection) => {
    return sponsorGroups ? (
        <div className="sponsor-groups-wrap">
            {sponsorGroups.map((sponsorGroup: SponsorGroup, index: number) => (
                <div className="mb-7" key={index}>
                    {(sponsorGroup.title || sponsorGroup.groupTitle) && (
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="mb-5">
                                    {sponsorGroup.groupTitle ? (
                                        <CustomTitle data={sponsorGroup.groupTitle} />
                                    ) : (
                                        <>
                                            {sponsorGroup.title && (
                                                <h3
                                                    className={`${classList['sponsor-group-title']} mb-0 h4 text-center text-capitalize`}
                                                >
                                                    {sponsorGroup.title}
                                                </h3>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row justify-content-center">
                        {sponsorGroup.sponsors.map((sponsor: Sponsor, sponsorIndex: number) => (
                            <div
                                key={sponsorIndex}
                                className={`col-md align-items-center ${classList['sponsor-group-col']}`}
                            >
                                {sponsor.image && (
                                    <div className="bg-white h-100 d-flex align-items-center p-5 shadow shadow-xl">
                                        <CustomImage data={sponsor.image} />
                                    </div>
                                )}
                                {sponsor.title && (
                                    <h4 className="text-center text-uppercase">{sponsor.title}</h4>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    ) : null
}

export default SponsorsSectionTemplateOne
