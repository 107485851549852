enum WidthUnit {
    PIXELS = 'Pixels',
    PERCENT = 'Percent',
}

export const widthUnitMapping: { Pixels: string, Percent: string } = {
    'Pixels': 'px',
    'Percent': '%'
}

export default WidthUnit
