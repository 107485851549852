import React from 'react'
import { connect } from 'react-redux'
import { selectWebsite } from '../../redux/selectors/cms'
import { selectQuizzes } from '../../redux/selectors/quizzes'
import { fetchQuizzes } from '../../redux/actions/quizzes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import Quiz from '../../class/models/quiz'
import QuizComponent from './Quiz.component'
import Button from '../Button/Button.component'

interface IProps {
    fetchQuizzes?: any
    quizzes?: any
}

interface IState {
    showQuizzes: boolean
}

const mapStateToProps: any = (state: any) => {
    return {
        websiteData: selectWebsite(state),
        quizzes: selectQuizzes(state),
    }
}

const actions = {
    fetchQuizzes,
}

class QuizzesComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            showQuizzes: false,
        }
    }

    componentDidMount() {
        this.props.fetchQuizzes('scorecard3')
    }

    toggleShowQuizzes = () => {
        this.setState((prevState) => {
            return {
                showQuizzes: !prevState.showQuizzes,
            }
        })
    }

    render() {
        const {
            quizzes: { data },
        } = this.props
        const { showQuizzes } = this.state
        return (
            <>
                <div className="d-flex justify-content-center mb-3">
                    <Button
                        type="button"
                        className="btn btn-primary d-flex align-items-center"
                        onClick={this.toggleShowQuizzes}
                    >
                        <div className="mr-2">{!showQuizzes ? 'View Quizzes' : 'Hide Quizzes'}</div>
                        <FontAwesomeIcon icon={!showQuizzes ? faAngleDoubleDown : faTimes} />
                    </Button>
                </div>
                {showQuizzes && (
                    <div className="row">
                        {data.map((quiz: Quiz, index: number) => (
                            <div className="col-md-4" key={index}>
                                <QuizComponent quiz={quiz} />
                            </div>
                        ))}
                    </div>
                )}
            </>
        )
    }
}

const Quizzes = connect(mapStateToProps, actions)(QuizzesComponent)
export default Quizzes
