/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allAttendees = /* GraphQL */ `
  query AllAttendees($after: String, $first: Int) {
    allAttendees(after: $after, first: $first) {
      code
      email
      error {
        exists
        message
      }
      eventId
      eventInstanceId
      firstName
      hostId
      isHost
      lastName
      roomId
      userId
    }
  }
`;
export const allMessage = /* GraphQL */ `
  query AllMessage($after: String, $conversationId: ID!, $first: Int) {
    allMessage(after: $after, conversationId: $conversationId, first: $first) {
      eventId
      message
      messageDateTime
      messageId
    }
  }
`;
export const allMessageConnection = /* GraphQL */ `
  query AllMessageConnection(
    $after: String
    $conversationId: ID!
    $first: Int
  ) {
    allMessageConnection(
      after: $after
      conversationId: $conversationId
      first: $first
    ) {
      messages {
        content
        conversationId
        createdAt
        id
        isSent
        sender
      }
      nextToken
    }
  }
`;
export const allMessageFrom = /* GraphQL */ `
  query AllMessageFrom(
    $after: String
    $conversationId: ID!
    $first: Int
    $sender: String!
  ) {
    allMessageFrom(
      after: $after
      conversationId: $conversationId
      first: $first
      sender: $sender
    ) {
      eventId
      message
      messageDateTime
      messageId
    }
  }
`;
export const getEventQuestions = /* GraphQL */ `
  query GetEventQuestions($eventId: String) {
    getEventQuestions(eventId: $eventId) {
      eventId
      questionId
      question
      answers {
        answerId
        quizId
        answer
        userId
      }
      answerKey
      timer
    }
  }
`;
export const getAttendeeByIdAndEvent = /* GraphQL */ `
  query GetAttendeeByIdAndEvent($roomId: String!, $userId: String!) {
    getAttendeeByIdAndEvent(roomId: $roomId, userId: $userId) {
      code
      email
      error {
        exists
        message
      }
      eventId
      eventInstanceId
      firstName
      hostId
      joinInfo {
        attendeeInfo
        meetingInfo
        roomId
      }
      lastName
      roomId
      userId
    }
  }
`;
export const getAttendees = /* GraphQL */ `
  query GetAttendees($eventId: ID!) {
    getAttendees(eventId: $eventId) {
      code
      email
      error {
        exists
        message
      }
      eventId
      eventInstanceId
      firstName
      hostId
      isHost
      lastName
      roomId
      userId
    }
  }
`;
export const getAttendeesByRoom = /* GraphQL */ `
  query GetAttendeesByRoom($roomId: String!) {
    getAttendeesByRoom(roomId: $roomId) {
      code
      email
      error {
        exists
        message
      }
      eventId
      eventInstanceId
      firstName
      hostId
      isHost
      lastName
      roomId
      userId
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($event: ID!) {
    getEvent(event: $event) {
      error {
        exists
        message
      }
      eventCreateDate
      eventId
      eventInstances {
        cmsId
        eventDate
        eventDateEndTime
        eventDateStartTime
        eventId
        eventInstanceId
        eventRoomCount
        eventInstanceName
      }
      eventName
      eventTypeId
      orgId
      cmsId
    }
  }
`;
export const getEvents = /* GraphQL */ `
  query GetEvents($eventId: ID, $eventTypeId: Int, $orgId: ID) {
    getEvents(eventId: $eventId, eventTypeId: $eventTypeId, orgId: $orgId) {
      error {
        exists
        message
      }
      eventCreateDate
      eventId
      eventInstances {
        cmsId
        eventDate
        eventDateEndTime
        eventDateStartTime
        eventId
        eventInstanceId
        eventRoomCount
        eventInstanceName
      }
      eventName
      eventTypeId
      orgId
      cmsId
    }
  }
`;
export const getEventTypes = /* GraphQL */ `
  query GetEventTypes {
    getEventTypes {
      eventTypeId
      eventTypeName
    }
  }
`;
export const getEventsByOrganization = /* GraphQL */ `
  query GetEventsByOrganization($orgId: ID) {
    getEventsByOrganization(orgId: $orgId) {
      error {
        exists
        message
      }
      eventCreateDate
      eventId
      eventInstances {
        cmsId
        eventDate
        eventDateEndTime
        eventDateStartTime
        eventId
        eventInstanceId
        eventRoomCount
        eventInstanceName
      }
      eventName
      eventTypeId
      orgId
      cmsId
    }
  }
`;
export const getEventInstances = /* GraphQL */ `
  query GetEventInstances($eventInstanceId: ID, $eventId: ID, $cmsId: String) {
    getEventInstances(
      eventInstanceId: $eventInstanceId
      eventId: $eventId
      cmsId: $cmsId
    ) {
      cmsId
      error {
        exists
        message
      }
      eventDate
      eventDateEndTime
      eventDateStartTime
      eventId
      eventInstanceId
      eventRoomCount
      eventInstanceName
      sponsors {
        sponsorId
        sponsorLogoUrl
        sponsorName
        sponsorUrl
        sponsorOrgId
        sponsorCreatedDate
        sponsorUpdatedDate
      }
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($orgId: ID!) {
    getOrganization(orgId: $orgId) {
      error {
        exists
        message
      }
      orgCreateDate
      orgId
      orgName
      orgTypeId
      events {
        eventCreateDate
        eventId
        eventName
        eventTypeId
        orgId
        cmsId
      }
      groupId
    }
  }
`;
export const getOrganizationTypes = /* GraphQL */ `
  query GetOrganizationTypes {
    getOrganizationTypes {
      error {
        exists
        message
      }
      orgTypeId
      orgTypeName
    }
  }
`;
export const getOrganizations = /* GraphQL */ `
  query GetOrganizations($orgId: ID, $orgTypeId: Int) {
    getOrganizations(orgId: $orgId, orgTypeId: $orgTypeId) {
      error {
        exists
        message
      }
      orgCreateDate
      orgId
      orgName
      orgTypeId
      events {
        eventCreateDate
        eventId
        eventName
        eventTypeId
        orgId
        cmsId
      }
      groupId
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($personId: ID!) {
    getPerson(personId: $personId) {
      firstName
      identifier
      lastName
      orgId
      orgName
      personId
      position
    }
  }
`;
export const getRoomByEventIdAndHost = /* GraphQL */ `
  query GetRoomByEventIdAndHost($eventInstanceId: String, $hostId: String) {
    getRoomByEventIdAndHost(
      eventInstanceId: $eventInstanceId
      hostId: $hostId
    ) {
      cmsRoomId
      error {
        exists
        message
      }
      eventInstanceId
      roomHostId
      roomHostName
      roomId
      roomTitle
    }
  }
`;
export const getRoomsByEventInstance = /* GraphQL */ `
  query GetRoomsByEventInstance($eventInstanceId: String) {
    getRoomsByEventInstance(eventInstanceId: $eventInstanceId) {
      cmsRoomId
      error {
        exists
        message
      }
      eventInstanceId
      roomHostId
      roomHostName
      roomId
      roomTitle
    }
  }
`;
export const getScore = /* GraphQL */ `
  query GetScore($eventId: String!) {
    getScore(eventId: $eventId) {
      EventDate
      eventId
      home
      homeScore
      visitor
      visitorScore
    }
  }
`;
export const me = /* GraphQL */ `
  query Me($email: String!) {
    me(email: $email) {
      id
      firstName
      lastName
      email
      emailVerified
      events {
        id
        date
        startTime
        endTime
        cmsId
        code
        registrationDate
        instanceId
        name
        orgId
        created
        typeId
        streamUrl
        subdomain
      }
    }
  }
`;
export const verifyRegisteredUser = /* GraphQL */ `
  query VerifyRegisteredUser($cmsId: String!, $code: String!, $email: String!) {
    verifyRegisteredUser(cmsId: $cmsId, code: $code, email: $email) {
      code
      email
      error {
        exists
        message
      }
      eventId
      eventInstanceId
      firstName
      hostId
      isHost
      lastName
      roomId
      userId
    }
  }
`;
export const getUserEventRoomLinksByUserEmail = /* GraphQL */ `
  query GetUserEventRoomLinksByUserEmail($userEmail: String!) {
    getUserEventRoomLinksByUserEmail(userEmail: $userEmail) {
      userId
      userEmail
      emailVerified
      userFirstName
      userLastName
      eventRegisteredUserId
      eventUserCode
      eventInstanceId
      roomId
      roomHostId
      isActive
      roomTitle
      cmsRoomId
      chimeMeetingId
    }
  }
`;
export const group = /* GraphQL */ `
  query Group($id: ID!) {
    group(id: $id) {
      ... on Group {
        id
        name
        organizations {
          orgCreateDate
          orgId
          orgName
          orgTypeId
          groupId
        }
      }
      ... on Error {
        exists
        message
      }
    }
  }
`;
export const groups = /* GraphQL */ `
  query Groups {
    groups {
      id
      name
      organizations {
        orgCreateDate
        orgId
        orgName
        orgTypeId
        groupId
      }
    }
  }
`;
export const getSponsor = /* GraphQL */ `
  query GetSponsor($sponsorId: ID!) {
    getSponsor(sponsorId: $sponsorId) {
      sponsorId
      sponsorLogoUrl
      sponsorName
      sponsorUrl
      sponsorOrgId
      sponsorCreatedDate
      sponsorUpdatedDate
    }
  }
`;
export const getOrgSponsors = /* GraphQL */ `
  query GetOrgSponsors($orgId: ID!) {
    getOrgSponsors(orgId: $orgId) {
      sponsorId
      sponsorLogoUrl
      sponsorName
      sponsorUrl
      sponsorOrgId
      sponsorCreatedDate
      sponsorUpdatedDate
    }
  }
`;
export const getEventInstanceSponsors = /* GraphQL */ `
  query GetEventInstanceSponsors($eventInstanceId: ID!) {
    getEventInstanceSponsors(eventInstanceId: $eventInstanceId) {
      sponsorId
      sponsorLogoUrl
      sponsorName
      sponsorUrl
      sponsorOrgId
      sponsorCreatedDate
      sponsorUpdatedDate
    }
  }
`;
