import ContentfulMediaAsset from './ContentfulMediaAsset'
import FooterSection from './footer'
import HeroSection from './hero-section'
import Image from './image'
import IntroSection from './intro-section'
import Section from './section'
import Settings from './settings'
import SponsorGroup from './sponsor-group'
import VideoChat from './video-chat'
import BreakoutRoom from './breakout-room'

export default class Website {
    id: string
    title: string
    subdomain: string
    eventDate: string
    eventEndDate: string
    logo: Image
    favicon?: ContentfulMediaAsset
    heroSection?: HeroSection
    introSection: any
    pollIds?: string[]
    showQuiz: boolean
    sections: Section[]
    sponsorSectionGroups?: SponsorGroup[]
    footer: FooterSection | null
    copyright: string
    videoChat?: VideoChat
    settings?: Settings
    breakoutRoom?: BreakoutRoom

    constructor(
        websiteData: any,
        websiteSectionsResponse: any,
        sponsorsData: any,
        speakersData: any,
        prizesData: any
    ) {
        this.id = websiteData.sys.id
        this.title = websiteData.title
        this.subdomain = websiteData.subdomain
        this.eventDate = websiteData.eventDate
        this.eventEndDate = websiteData.eventEndDate
        this.logo = new Image(websiteData.logo)
        this.favicon = websiteData.favicon
            ? new ContentfulMediaAsset(websiteData.favicon)
            : undefined
        this.heroSection = websiteData.heroSection
            ? new HeroSection(websiteData.heroSection)
            : undefined
        this.introSection = new IntroSection(websiteData.introSection)
        this.showQuiz = !!websiteData.showQuiz
        this.pollIds = websiteData.pollIds
        this.sections = websiteSectionsResponse
            ? websiteSectionsResponse.map(
                  (sectionCollectionItem: any) =>
                      new Section(
                          sectionCollectionItem,
                          sponsorsData,
                          speakersData,
                          prizesData,
                          websiteData.videoChat
                      )
              )
            : []
        this.footer = websiteData.footer ? new FooterSection(websiteData.footer) : null
        this.copyright = websiteData.copyright
        this.videoChat = websiteData.videoChat ? new VideoChat(websiteData.videoChat) : undefined
        this.settings = websiteData.settings ? new Settings(websiteData.settings) : undefined
        this.breakoutRoom = websiteData.breakoutRoom
            ? new BreakoutRoom(websiteData.breakoutRoom)
            : undefined
    }
}
