import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { IPropWithData } from '../../class/interfaces/redux'
import { Template } from '../../enums/TemplateTypes'
import { selectWebsite } from '../../redux/selectors/cms'
import View from '../../components/View/View.component'
import HeroSectionTemplateOne from '../../components/HeroSection/HeroSectionTemplateOne/HeroSectionTemplateOne.component'
import HeroSectionTemplateTwo from '../../components/HeroSection/HeroSectionTemplateTwo/HeroSectionTemplateTwo.component'
import Website from '../../class/models/website'

interface IStateProps {
    websiteData: IPropWithData<Website>
}

const mapStateToProps = (state: any): IStateProps => {
    return {
        websiteData: selectWebsite(state),
    }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux

/**
 * Chat moderation:
 * - Delete messages.
 * - Block users.
 * - Pause chat for all users.
 * - Make it so admins have a specific logo so they are set apart.
 */

const HeroSectionComponentContainerClass = ({ websiteData }: IProps) => {
    const data = websiteData.data.heroSection

    const viewStyles: any = {}
    const imageContainerStyles: any = {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
    }

    if (data) {
        imageContainerStyles.minHeight = `${
            data.minHeight !== undefined
                ? data.minHeight
                : data.image
                ? data.image.asset.height
                : 250
        }px`

        if (data.maxHeight !== undefined) {
            imageContainerStyles.maxHeight = `${data.maxHeight}px`
        }

        if (data.image) {
            imageContainerStyles.backgroundImage = `url(${data.image.asset.url})`
        }

        if (data.backgroundColor) {
            imageContainerStyles.backgroundColor = data.backgroundColor
        }

        if (data.paddingTop) {
            viewStyles.paddingTop = data.paddingTop
        }

        if (data.paddingBottom) {
            viewStyles.paddingBottom = data.paddingBottom
        }
    }

    return !data ? null : (
        <section className="d-flex align-items-center" style={imageContainerStyles}>
            <div className="w-100 height-100">
                <View style={viewStyles}>
                    {data.template === Template.TEMPLATE1 && (
                        <HeroSectionTemplateOne data={data} websiteData={websiteData} />
                    )}
                    {data.template === Template.TEMPLATE2 && (
                        <HeroSectionTemplateTwo data={data} websiteData={websiteData} />
                    )}
                </View>
            </div>
        </section>
    )
}

const HeroSectionContainer = connect(mapStateToProps)(HeroSectionComponentContainerClass)
export default HeroSectionContainer
