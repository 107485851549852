import { instanceQuizzes } from './instances'

export const getQuizzes = async (eventId: string) => {
    const quizzesResponse = await instanceQuizzes.post('/', {
        query: `
            query questionQuery {
                getEventQuestions(filter: {eventId: {eq: "${eventId}"}}) {
                    items {
                        key
                        question
                        questionId
                        answers {
                            id
                            questionId
                            text
                        }
                    }
                }
            }
        `,
    })

    if (!quizzesResponse.data.data.getEventQuestions.items) {
        throw new Error('QUESTIONS_NOT_FOUND')
    }
    
    return quizzesResponse.data.data.getEventQuestions.items
}

export const putAnswer = async ({ questionId, email, answerKey }: any) => {
    const putAnswerResponse = await instanceQuizzes.post('/', {
        query: `
            mutation UserAnswer {
                putAnswer(key: "${answerKey}", questionId: "${questionId}", user: "${email}") {
                    answerId
                    user
                    key
                    questionId
                }
            }
        `,
    })

    if (
        putAnswerResponse.data &&
        putAnswerResponse.data.data &&
        putAnswerResponse.data.data.putAnswer
    ) {
        return putAnswerResponse.data.data.putAnswer
    } else {
        throw new Error('Error putting answer.')
    }
}
