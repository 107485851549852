import LinkGroupOrientation from '../../enums/LinkGroupOrientation'
import ContentfulLink from './link'

export default class LinkGroup {
    orientation: LinkGroupOrientation
    links: ContentfulLink[]

    constructor(rawData: any) {
        const data = rawData || {}
        this.orientation = data.orientation || LinkGroupOrientation.ROW
        this.links =
            data.linksCollection && data.linksCollection.items
                ? data.linksCollection.items.map((link: ContentfulLink) => new ContentfulLink(link))
                : []
    }
}
