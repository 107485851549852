import React from 'react'
import Footer from '../../components/Footer/Footer.component'
// import Header from "../../components/Header/Header.component";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from '../../pages/home/home'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import User from '../../services/user'
import { connect, ConnectedProps } from 'react-redux'
import { selectIsMobile, selectWebsite } from '../../redux/selectors/cms'
import {
    fetchWebsite,
    clearErrorsCms,
    setEventStarted,
    setEventEnded,
    setIsMobile,
} from '../../redux/actions/cms'
import moment from 'moment'
import classNames from 'classnames'
import { IPropWithData } from '../../class/interfaces/redux'
import Website from '../../class/models/website'

export const loadingClass = 'loading'

interface IComponentState {
    startEndInterval?: number
}

interface IStateProps {
    websiteData: IPropWithData<Website>
    isMobile: boolean
}

const mapStateToProps = (state: IStateProps): IStateProps => {
    return {
        websiteData: selectWebsite(state),
        isMobile: selectIsMobile(state),
    }
}

const actions = {
    fetchWebsite,
    clearErrorsCms,
    setEventStarted,
    setEventEnded,
    setIsMobile,
}

const connector = connect(mapStateToProps, actions)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux

class AppComponent extends React.Component<IProps, IComponentState> {
    constructor(props: any) {
        super(props)
        this.state = {
            startEndInterval: undefined,
        }
    }

    async componentDidMount() {
        document.documentElement.classList.add(loadingClass)
        User.initUser()
        this.props.fetchWebsite()
        this.setIsMobile()
        window.addEventListener('resize', this.setIsMobile)
    }

    componentDidUpdate(prevProps: any) {
        const {
            websiteData: { fetched: prevFetched },
        } = prevProps
        const {
            websiteData: { fetched: currFetched, error },
        } = this.props

        if (error) {
            console.error('Error loading website: ', error)
        }

        if (!prevFetched && currFetched) {
            this.setFavicon()
            this.setSetTitle()
            this.setSiteStyles()
            this.setEventStartEnd()
            this.startEventStartEndInterval()
        }
    }

    componentWillUnmount() {
        this.stopStartEndInterval()
        window.removeEventListener('resize', this.setIsMobile)
    }

    startEventStartEndInterval = () => {
        const { startEndInterval } = this.state

        if (!startEndInterval) {
            const intervalId = window.setInterval(() => {
                this.setEventStartEnd()
            }, 1000)
            this.setState({ startEndInterval: intervalId })
        }
    }

    stopStartEndInterval = () => {
        const { startEndInterval } = this.state
        if (startEndInterval) {
            window.clearInterval(startEndInterval)
            this.setState({ startEndInterval: undefined })
        }
    }

    setEventStartEnd = () => {
        const {
            websiteData: {
                data: { eventDate, eventEndDate },
            },
        } = this.props
        const now = moment()
        const eventStarted = now.isSameOrAfter(moment(eventDate))
        const eventEnded = now.isSameOrAfter(moment(eventEndDate))

        if (eventStarted) {
            this.props.setEventStarted(true)
        }
        if (eventEnded) {
            this.props.setEventEnded(true)
        }

        if (eventStarted && eventEnded) {
            this.stopStartEndInterval()
        }
    }

    /**
     * Set redux state isMobile.
     * TODO: mobile width should be dynamic from Contentful
     */
    setIsMobile = () => {
        const { isMobile } = this.props
        const isNowMobile = window.innerWidth < 1025

        if (isMobile !== isNowMobile) {
            this.props.setIsMobile(isNowMobile)
        }
    }

    /**
     * Set the website favicon based on the data from Contentful.
     */
    setFavicon() {
        const {
            websiteData: { data },
        } = this.props
        const favicon: any = document.getElementById('favicon')

        if (data.favicon && favicon) {
            favicon.href = data.favicon.url
        }
    }

    setSetTitle = () => {
        const {
            websiteData: {
                data: { title },
            },
        } = this.props

        document.title = title
    }

    setSiteStyles = () => {
        const {
            websiteData: {
                data: { settings },
            },
        } = this.props

        if (settings) {
            const htmlElement: any = document.getElementsByTagName('HTML')[0]
            if (settings.baseFontSize) {
                if (htmlElement) {
                    htmlElement.style.fontSize = settings.baseFontSize
                }
                document.body.style.fontSize = settings.baseFontSize
            }
            if (settings.primaryColor) {
                if (htmlElement) {
                    htmlElement.style.color = settings.primaryColor
                }
                document.body.style.color = settings.primaryColor
            }
        }
    }

    render() {
        const {
            websiteData: { data, fetched, error },
        } = this.props

        return !fetched && !error ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <FontAwesomeIcon icon={faSpinner} spin={true} size="3x" />
            </div>
        ) : (
            <div
                className={classNames('App', {
                    'h-100 d-flex align-items-center justify-content-center': error,
                })}
            >
                {fetched && !error ? (
                    <Router>
                        {/* <Header logo={websiteData.data.logo}/> */}
                        <Switch>
                            <Route path="/">
                                <Home website={data} />
                            </Route>
                        </Switch>
                        {data.footer && <Footer />}
                    </Router>
                ) : (
                    <div>No Website Found</div>
                )}
            </div>
        )
    }
}

const App = connect(mapStateToProps, actions)(AppComponent)
export default App
