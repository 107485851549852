import React from 'react'
import ReactMarkdownWithHtml from 'react-markdown'
import HeroSectionModel from '../../../class/models/hero-section'
import CustomImage from '../../CustomImage/CustomImage'
import classNames from 'classnames'

interface IProps {
    data: HeroSectionModel
    websiteData: any
}

const HeroSectionTemplateTwo = ({ data, websiteData }: IProps) => {
    return (
        <div
            className={classNames('row align-items-center', {
                'justify-content-center': !data.overlayImage,
            })}
        >
            {websiteData && websiteData.fetched && (
                <div
                    className={classNames('col-lg-6', {
                        'mb-0': !data.overlayImage,
                        'mb-lg-0': data.overlayImage,
                    })}
                >
                    <div className="d-flex flex-column justify-content-center">
                        <div
                            className={classNames('d-flex justify-content-center mb-lg-0', {
                                'mb-5': data.text,
                            })}
                        >
                            <CustomImage data={websiteData.data.logo} />
                        </div>
                    </div>
                </div>
            )}
            {(data.overlayImage || data.text) && (
                <div className="col-lg-6 mb-lg-0">
                    {data.overlayImage && <CustomImage data={data.overlayImage} />}
                    {data.text && (
                        <div className="text-white text-lg-left text-center">
                            <ReactMarkdownWithHtml allowDangerousHtml={true} children={data.text} />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default HeroSectionTemplateTwo
