import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import Sponsor from '../../../class/models/sponsor'
import SponsorGroup from '../../../class/models/sponsor-group'
import { selectWebsite } from '../../../redux/selectors/cms'
import CustomImage from '../../CustomImage/CustomImage'
import classList from './SponsorSectionTemplateThree.module.scss'

const mapStateToProps: any = (state: any) => {
    return {
        websiteData: selectWebsite(state),
    }
}

interface ISponsorsSection {
    data: { sponsorGroups: SponsorGroup[] }
    websiteData?: any
}

class SponsorsSectionTemplateThreeComponent extends React.Component<ISponsorsSection> {
    getTitleToUse = (sponsorGroup: SponsorGroup): string => {
        let title = ''

        if (sponsorGroup.groupTitle) {
            title = sponsorGroup.groupTitle.title
        } else if (sponsorGroup.title) {
            title = sponsorGroup.title
        }
        return title
    }
    render() {
        const {
            data: { sponsorGroups },
            websiteData: {
                data: { settings },
            },
        } = this.props
        const featuredGroups = sponsorGroups.filter(
            (sponsorGroup: SponsorGroup) => !!sponsorGroup.featuredGroup
        )
        const nonFeaturedGroups = sponsorGroups.filter(
            (sponsorGroup: SponsorGroup) => !sponsorGroup.featuredGroup
        )
        const lineStyle: any = {}
        if (settings && settings.secondaryColor) {
            lineStyle.borderColor = settings.secondaryColor
        }
        const oddNumberNonFeatured = nonFeaturedGroups.length % 2 !== 0
        return sponsorGroups ? (
            <div className="sponsor-groups-wrap">
                {featuredGroups &&
                    !!featuredGroups.length &&
                    featuredGroups.map((sponsorGroup: SponsorGroup, index: number) => (
                        <div key={`${sponsorGroup.title}-${index}`} className="mb-5">
                            {(sponsorGroup.groupTitle || sponsorGroup.title) && (
                                <h3 className="h5 font-weight-normal text-center">
                                    {this.getTitleToUse(sponsorGroup)}
                                </h3>
                            )}
                            {sponsorGroup.sponsors &&
                                !!sponsorGroup.sponsors.length &&
                                sponsorGroup.sponsors.map(
                                    (sponsor: Sponsor, sponsorIndex: number) => (
                                        <div
                                            key={`${sponsor.title}-featuredSponsor-${sponsorIndex}`}
                                            className={classNames({
                                                'mb-3':
                                                    sponsorGroup.sponsors.length > 1 &&
                                                    sponsorIndex !==
                                                        sponsorGroup.sponsors.length - 1,
                                            })}
                                        >
                                            {sponsor.image && (
                                                <div className="text-center">
                                                    <CustomImage data={sponsor.image} />
                                                </div>
                                            )}
                                            {sponsor.title && (
                                                <h4 className="h6 text-center text-uppercase">
                                                    {sponsor.title}
                                                </h4>
                                            )}
                                        </div>
                                    )
                                )}
                        </div>
                    ))}
                {nonFeaturedGroups && !!nonFeaturedGroups.length && (
                    <div className="row">
                        {nonFeaturedGroups.map((sponsorGroup: SponsorGroup, index: number) => {
                            const isOdd = index % 2 === 0
                            const shouldBeFullColumn =
                                oddNumberNonFeatured && index === nonFeaturedGroups.length - 1
                            return (
                                <div
                                    key={`${sponsorGroup.title}-nonFeatured-${index}`}
                                    className={classNames({
                                        'col-lg-8': isOdd && !shouldBeFullColumn,
                                        'col-lg-4': !isOdd && !shouldBeFullColumn,
                                        'col-12': shouldBeFullColumn,
                                        [classList['line-left']]: !isOdd,
                                    })}
                                    style={lineStyle}
                                >
                                    {(sponsorGroup.groupTitle || sponsorGroup.title) && (
                                        <h3
                                            className={classNames(
                                                'body-small text-uppercase mb-1 text-lg-left text-center font-weight-medium',
                                                {
                                                    'text-lg-center':
                                                        shouldBeFullColumn ||
                                                        (isOdd && !shouldBeFullColumn),
                                                }
                                            )}
                                        >
                                            {this.getTitleToUse(sponsorGroup)}
                                        </h3>
                                    )}
                                    <div
                                        className={classNames({
                                            'd-lg-flex flex-wrap justify-content-end': isOdd,
                                            'justify-content-lg-center': shouldBeFullColumn,
                                        })}
                                    >
                                        {sponsorGroup.sponsors.map(
                                            (sponsor: Sponsor, sponsorIndex: number) => (
                                                <div
                                                    key={`${sponsor.title}-sponsor-${sponsorIndex}`}
                                                    className={classNames(
                                                        classList['sponsor-title'],
                                                        'text-lg-left text-center',
                                                        {
                                                            'text-lg-center': shouldBeFullColumn,
                                                            [classList[
                                                                'sponsor-title-larger-column'
                                                            ]]: isOdd && !shouldBeFullColumn,
                                                            'mr-0':
                                                                sponsorGroup.sponsors.length % 2 !==
                                                                    0 &&
                                                                sponsorGroup.sponsors.length - 1 ===
                                                                    sponsorIndex,
                                                        }
                                                    )}
                                                >
                                                    {sponsor.title && sponsor.title}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        ) : null
    }
}

const SponsorsSectionTemplateThree = connect(mapStateToProps)(SponsorsSectionTemplateThreeComponent)
export default SponsorsSectionTemplateThree
