import { store } from '..'
import { IPropWithData } from '../class/interfaces/redux'
import { ChatType } from '../class/models/video-chat'
import Website from '../class/models/website'

export const getSubdomain = () => {
    const subdomain = document.location.host.split('.')[0]
    return subdomain.includes('localhost') ? 'dev' : subdomain
}

export const isChatValid = () => {
    const storeState = store.getState()
    const website: IPropWithData<Website> = storeState.cms.get('website').toJS()

    const videoChat = website.data.videoChat

    if (!videoChat) return false

    return (
        (!!videoChat &&
            ((videoChat.chatType === ChatType.MINNIT && !!videoChat.minnitChatId) ||
                (videoChat.chatType === ChatType.IFRAME && !!videoChat.chatIFrame))) ||
        videoChat.chatType === ChatType.CUSTOM
    )
}
