import React from 'react'
import ContentCollectionItem from '../../../class/models/content-collection'
import HeroSectionModel from '../../../class/models/hero-section'
import CustomImage from '../../CustomImage/CustomImage'
import DynamicContent from '../../DynamicContent/DynamicContent'

interface IProps {
    data: HeroSectionModel
    websiteData: any
}

const HeroSectionTemplateOne = ({ data, websiteData }: IProps) => {
    return (
        <div className="d-flex align-items-center justify-content-between flex-column">
            {data.content &&
                data.content.map((content: ContentCollectionItem, index: number) => (
                    <DynamicContent key={index} type={content.type} data={content.data} />
                ))}
            {websiteData && websiteData.fetched && <CustomImage data={websiteData.data.logo} />}
        </div>
    )
}

export default HeroSectionTemplateOne
