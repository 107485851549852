import Image from './image'

export default class Prize {
    title: string
    description: string
    image?: Image

    constructor(data: any) {
        this.title = data.title
        this.description = data.description
        this.image = data.image ? new Image(data.image) : undefined
    }
}
