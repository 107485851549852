import React from 'react'
import Quiz from '../../class/models/quiz'
import QuizAnswer from '../../class/models/quiz-answer'
import { selectPutQuizzAnswer } from '../../redux/selectors/quizzes'
import { putQuizAnswer } from '../../redux/actions/quizzes'
import Button from '../Button/Button.component'
import { connect } from 'react-redux'
import { selectUser } from '../../redux/selectors/auth'
import classNames from 'classnames'
import classList from './Quizzes.module.scss'
import Analytics from '../../providers/analytics'
import ReactHtmlParser from 'react-html-parser'

const mapStateToProps: any = (state: any) => {
    return {
        putQuizAnswerData: selectPutQuizzAnswer(state),
        userData: selectUser(state),
    }
}

const actions = {
    putQuizAnswer,
}

interface IProps {
    quiz: Quiz
    userData?: any
    putQuizAnswerData?: any
    putQuizAnswer: (data: any) => void
}

interface IState {
    selectedAnswer: string
    answers: any
}

const LOCAL_STORAGE_KEY = 'scorecard1_answers'

// TODO: Finish with sending data to database and storing in local storage.
class QuizComponentComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            selectedAnswer: '',
            answers: this.getCurrentAnswers(),
        }
    }

    componentDidUpdate(prevProps: IProps) {
        this.checkPutAnswer(prevProps)
    }

    checkPutAnswer(prevProps: IProps) {
        const {
            putQuizAnswerData: { fetched: prevFetched },
        } = prevProps
        const {
            putQuizAnswerData: { fetched: currFetched, data },
            quiz: { questionId },
        } = this.props
        const { answers } = this.state

        if (!prevFetched && currFetched && questionId === data.questionId) {
            const newAnswers = {
                ...answers,
                [questionId]: data.key,
            }

            this.setState({
                answers: newAnswers,
            })

            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newAnswers))
        }
    }

    getCurrentAnswers = () => {
        const currentAnswersString = localStorage.getItem(LOCAL_STORAGE_KEY)
        let answers = {}
        if (currentAnswersString) {
            try {
                answers = JSON.parse(currentAnswersString)
            } catch (e) {
                answers = {}
            }
        }

        return answers
    }

    onChange = (event: any) => {
        this.setState({
            selectedAnswer: event.target.value,
        })
    }

    onSubmit = (e: any) => {
        e.preventDefault()
        const { selectedAnswer } = this.state
        const { userData, quiz } = this.props
        this.props.putQuizAnswer({
            questionId: quiz.questionId,
            email: userData.email,
            answerKey: selectedAnswer,
        })
        Analytics.track('Quiz Submission', { ...quiz, ...userData, selectedAnswer })
    }

    /**
     * Whether or not the user answered the question correctly.
     * @param answer the answer to check against.
     * @returns true/false.
     */
    answeredQuestionIncorrectly = (answer: any) => {
        const { answers } = this.state
        const {
            quiz: { key, questionId },
        } = this.props
        const userAnswer = answers[questionId]
        return userAnswer === answer.id && userAnswer !== key
    }

    /**
     * Whether or not the user has answered the question.
     * @returns true/false.
     */
    answeredQuestion = () => {
        const { answers } = this.state
        const {
            quiz: { questionId },
        } = this.props
        return !!answers[questionId]
    }

    render() {
        const { quiz } = this.props
        const { selectedAnswer, answers } = this.state
        return (
            <form
                onSubmit={this.onSubmit}
                className={classNames('h-100', {
                    [classList['answered-question']]: this.answeredQuestion(),
                })}
            >
                <div className="card h-100">
                    <div className="card-header">{quiz.question}</div>
                    <div className="card-body">
                        {quiz.answers.map((answer: QuizAnswer, index: number) => {
                            const inputId = `${answer.questionId}-${answer.id}`
                            const answeredQuestion = this.answeredQuestion()
                            const classes = {
                                [classList['correct-answer']]:
                                    answeredQuestion && quiz.key === answer.id,
                                [classList['incorrect-answer-selected']]:
                                    answeredQuestion && this.answeredQuestionIncorrectly(answer),
                            }
                            return (
                                <div className="" key={index}>
                                    <div className={classNames('form-check', classes)}>
                                        <label className="form-check-label" htmlFor={inputId}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`${quiz.questionId}-answer`}
                                                id={inputId}
                                                value={answer.id}
                                                checked={
                                                    answeredQuestion
                                                        ? answers[quiz.questionId] === answer.id
                                                        : selectedAnswer === answer.id
                                                }
                                                disabled={answeredQuestion}
                                                onChange={this.onChange}
                                            />
                                            {ReactHtmlParser(answer.text)}
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                        <Button
                            type="submit"
                            className="btn-primary btn-sm"
                            disabled={this.answeredQuestion()}
                        >
                            Submit Answer
                        </Button>
                    </div>
                </div>
            </form>
        )
    }
}

const QuizComponent = connect(mapStateToProps, actions)(QuizComponentComponent)
export default QuizComponent
