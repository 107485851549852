import { createActions } from 'redux-actions'
import { takeLatest, call, all, put } from 'redux-saga/effects'
import { loginUser, logoutUser, registerUser } from '../../providers/auth'

import { defaultAction } from './utils'

const actions = createActions({
    AUTH: {
        LOGIN: defaultAction,
        LOGOUT: defaultAction,
        REGISTER: defaultAction,
        SET_USER: undefined,
        CLEAR_ERRORS: undefined,
    },
})

export const {
    auth: {
        login: { pending: login },
        logout: { pending: logout },
        register: { pending: register },
        setUser,
        clearErrors: clearErrorsAuth,
    },
} = actions

function* loginWatcher() {
    yield takeLatest(login, loginWorker)
}

function* loginWorker({ payload: { email, code } }) {
    try {
        const res = yield call(loginUser, email, code)

        yield put(actions.auth.login.fulfilled(res))
    } catch (e) {
        console.error(e)
        yield put(actions.auth.login.rejected(e))
    }
}

function* logoutWatcher() {
    yield takeLatest(logout, logoutWorker)
}

function* logoutWorker() {
    try {
        const res = yield call(logoutUser)

        yield put(actions.auth.logout.fulfilled(res))
    } catch (e) {
        console.error(e)
        yield put(actions.auth.logout.rejected(e))
    }
}

function* registerWatcher() {
    yield takeLatest(register, registerWorker)
}

function* registerWorker({ payload: { email, firstName, lastName } }) {
    try {
        const res = yield call(registerUser, { email, firstName, lastName })

        yield put(actions.auth.register.fulfilled(res))
    } catch (e) {
        console.error(e)
        yield put(actions.auth.register.rejected(e))
    }
}

export function* userWatcher() {
    yield all([call(loginWatcher), call(logoutWatcher), call(registerWatcher)])
}
