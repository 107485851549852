import VideoChat from './video-chat'

export default class VideoChatSection {
    title: string
    data: VideoChat

    constructor(sectionData: any, videoChatData: any) {
        this.title = sectionData.title
        this.data = new VideoChat(videoChatData)
    }
}
