import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { selectWebsite } from '../../redux/selectors/cms'
import classList from './View.module.scss'
import classNames from 'classnames'

interface IViewProps {
    containerFluid?: boolean
    spacingTopBottom?: boolean
    noSpacingBottom?: boolean
    children: ReactNode
    className?: string
    style?: any
    websiteData?: any
    containerVideo?: boolean
}

const mapStateToProps: any = (state: any) => {
    return {
        websiteData: selectWebsite(state),
    }
}

class ViewComponent extends React.Component<IViewProps> {
    render() {
        const {
            containerFluid = false,
            spacingTopBottom = true,
            noSpacingBottom,
            className,
            children,
            style,
            websiteData,
            containerVideo,
        } = this.props
        const content = spacingTopBottom ? (
            <div
                className={`${classList.content} content${noSpacingBottom ? ' pb-0' : ''}`}
                style={style}
            >
                {children}
            </div>
        ) : (
            children
        )

        const containerStyles: any = {}

        if (
            websiteData &&
            websiteData.data.settings.containerMaxWidth &&
            !containerFluid &&
            !containerVideo
        ) {
            containerStyles.maxWidth = websiteData.data.settings.containerMaxWidth
        }

        return (
            <div
                className={classNames(
                    {
                        'container-fluid': containerFluid,
                        container: !containerFluid,
                        [classList['container-video']]: containerVideo,
                    },
                    className
                )}
                style={containerStyles}
            >
                {content}
            </div>
        )
    }
}

const View = connect(mapStateToProps)(ViewComponent)
export default View
