import React from 'react'
import { Link } from 'react-router-dom'
import ContentfulLink from '../../class/models/link'
import classNames from 'classnames'
import { selectWebsite } from '../../redux/selectors/cms'
import { connect, ConnectedProps } from 'react-redux'
import ButtonStyle from '../../enums/ButtonStyle'
import { IPropWithData } from '../../class/interfaces/redux'
import Website from '../../class/models/website'

interface IStateProps {
    websiteData: IPropWithData<Website>
}

const mapStateToProps = (state: any): IStateProps => {
    return {
        websiteData: selectWebsite(state),
    }
}

interface IOwnProps {
    data: ContentfulLink
    className?: string
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux & IOwnProps

class CustomLinkComponent extends React.Component<IProps> {
    render() {
        const {
            data,
            className,
            websiteData: { data: websiteData },
        } = this.props

        const classes = {
            'btn btn-primary btn-white': data.button,
        }

        const styles: any = {}

        if (data.button) {
            switch (data.style) {
                case ButtonStyle.SECONDARY:
                    if (websiteData.settings) {
                        styles.backgroundColor = websiteData.settings.secondaryColor
                        styles.borderColor = websiteData.settings.secondaryColor
                        styles.color = '#ffffff'
                    }
                    break
                case ButtonStyle.PRIMARY:
                default:
                    if (websiteData.settings) {
                        styles.backgroundColor = websiteData.settings.primaryColor
                        styles.borderColor = websiteData.settings.primaryColor
                        styles.color = '#ffffff'
                    }
            }

            if (data.customBackgroundColor) {
                styles.backgroundColor = data.customBackgroundColor
                styles.borderColor = data.customBackgroundColor
            }

            if (data.width) {
                styles.width = '100%'
                styles.maxWidth = data.width
            }

            if (data.fontSize) {
                styles.fontSize = `${data.fontSize}px`
            }
        }

        if (data.textColor) {
            styles.color = data.textColor
        }

        return data.internalexternal === 'External' ? (
            <a
                href={data.link}
                target="_blank"
                rel="noreferrer"
                className={classNames(classes, className)}
                style={styles}
            >
                {data.title}
            </a>
        ) : (
            <Link to={data.link} className={classNames(classes, className)} style={styles}>
                {data.title}
            </Link>
        )
    }
}

const CustomLink = connect(mapStateToProps)(CustomLinkComponent)
export default CustomLink
