import Website from '../class/models/website'
import { getSubdomain } from '../services/utils'
import Contentful from './contentful'

export const getWebsite = async () => {
    const subdomain = getSubdomain()

    const websiteResponse = await Contentful.getWebsite(subdomain)
    const websiteSectionsResponse = await Contentful.getSections(subdomain)
    const websiteSponsorsResponse = await Contentful.getWebsiteSponsors(subdomain)
    const websiteSpeakersResponse = await Contentful.getSpeakers(subdomain)
    const websitePrizesResponse = await Contentful.getPrizes(subdomain)

    const website = new Website(
        websiteResponse,
        websiteSectionsResponse,
        websiteSponsorsResponse,
        websiteSpeakersResponse,
        websitePrizesResponse
    )
    return website
}
