import {createActions} from 'redux-actions';
import {
    takeLatest, call, all, put,
} from 'redux-saga/effects';
import { getMessages } from '../../providers/chat';

import {defaultAction} from './utils';

const actions = createActions({
    CHAT: {
        FETCH_MESSAGES: defaultAction,
    },
});

export const {
    chat: {
        fetchMessages: {pending: fetchMessages},
    },
} = actions;

function* fetchMessagesWatcher() {
    yield takeLatest(fetchMessages, fetchMessagesWorker);
}

function* fetchMessagesWorker() {
    try {
        const res = yield call(getMessages);

        yield put(actions.chat.fetchMessages.fulfilled(res));
    } catch (e) {
        console.error(e);
        yield put(actions.chat.fetchMessages.rejected(e));
    }
}

export function* chatWatcher() {
    yield all([
        call(fetchMessagesWatcher),
    ]);
}
