import {handleActions} from 'redux-actions';
import * as Immutable from 'immutable';
import { defaultFieldWithData, defaultReducerWithData } from './utils';

const initialState = Immutable.fromJS({
    messages: defaultFieldWithData,
});

export default handleActions({
    CHAT: {
        FETCH_MESSAGES: defaultReducerWithData('messages'),
    },
}, initialState);
