import VideoTypes from '../../enums/VideoTypes'
import ContentfulMediaAsset from './ContentfulMediaAsset'
import Copy from './copy'
import ContentfulLink from './link'

export enum ChatType {
    CUSTOM = 'Custom',
    MINNIT = 'Minnit',
    IFRAME = 'Iframe',
}

export default class VideoChat {
    eventDateTitle: string
    streamUrl?: string
    iframeUrl?: string
    eventInstanceId?: string
    channelId?: string
    typeOfVideoToShow: VideoTypes
    videoPoster?: ContentfulMediaAsset
    videoTitle?: string
    videoLogo?: ContentfulMediaAsset
    chatType: ChatType
    minnitChatId?: string

    chatIFrame?: string
    chatLogo?: ContentfulMediaAsset
    chatTitle: string
    showBelowContentBeforeEvent: boolean
    belowVideoCopy?: Copy
    button?: ContentfulLink
    captionerIframe?: string

    constructor(videoChatData: any) {
        const data = videoChatData || {}
        this.eventDateTitle = data.eventDateTitle || '{date}'
        this.streamUrl = data.streamUrl
        this.iframeUrl = data.iframeUrl
        this.eventInstanceId = data.eventInstanceId
        this.channelId = data.channelId
        this.typeOfVideoToShow = data.typeOfVideoToShow
        this.videoPoster = data.videoPoster ? new ContentfulMediaAsset(data.videoPoster) : undefined
        this.videoTitle = data.videoTitle
        this.videoLogo = data.videoLogo ? new ContentfulMediaAsset(data.videoLogo) : undefined
        this.chatType = data.chatType
        this.minnitChatId = data.minnitChatId
        this.chatIFrame = data.chatIFrame
        this.chatLogo = data.chatLogo ? new ContentfulMediaAsset(data.chatLogo) : undefined
        this.chatTitle = data.chatTitle
        this.showBelowContentBeforeEvent = !!data.showBelowContentBeforeEvent
        this.belowVideoCopy = data.belowVideoCopy ? new Copy(data.belowVideoCopy) : undefined
        this.button = data.button ? new ContentfulLink(data.button) : undefined
        this.captionerIframe = data.captionerIframe || undefined
    }
}
