import Image from './image'

export default class Speaker {
    name: string
    title?: string
    description?: string
    image?: Image

    constructor(data: any) {
        this.name = data.name
        this.title = data.title
        this.description = data.description
        this.image = data.image ? new Image(data.image) : undefined
    }
}
