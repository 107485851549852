import Speaker from './speaker'
import Title from './title'

export default class SpeakerGroup {
    title?: string
    groupTitle?: Title
    speakers?: Speaker[]

    constructor(data: any) {
        this.title = data.title
        this.groupTitle = data.groupTitle ? new Title(data.groupTitle) : undefined
        this.speakers = data.guestSpeakersCollection
            ? data.guestSpeakersCollection.items.map((speaker: any) => new Speaker(speaker))
            : []
    }
}
