import Image from './image'
import ContentfulLink from './link'
import SocialMediaLink from './social-media-link'

export default class FooterSection {
    title: string
    backgroundImage?: any
    backgroundColor?: string
    textColor?: string
    iconColor?: string
    links: ContentfulLink[]
    logo?: Image
    phone?: string
    copyright?: string
    socialMediaLinks?: SocialMediaLink[]

    constructor(rawData: any) {
        const data = rawData || {}
        this.title = data.title
        this.backgroundImage = data.backgroundImage
        this.backgroundColor = data.backgroundColor
        this.textColor = data.textColor
        this.iconColor = data.iconColor
        this.links =
            data.linksCollection && data.linksCollection.items
                ? data.linksCollection.items.map((link: any) => new ContentfulLink(link))
                : []
        this.logo = data.logo ? new Image(data.logo) : undefined
        this.phone = data.phone
        this.copyright = data.copyright
        this.socialMediaLinks = data.socialMediaLinks
            ? data.socialMediaLinks.map(
                  (socialMediaLink: any) => new SocialMediaLink(socialMediaLink)
              )
            : []
    }
}
