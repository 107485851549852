import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import SocialMediaLink from '../../class/models/social-media-link'
import {
    faFacebookF,
    faInstagram,
    faLinkedin,
    faPinterestP,
    faTwitter,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import SocialMediaTypes from '../../enums/SocialMediaTypes'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import classList from './SocialMediaLink.module.scss'
import { useSelector } from 'react-redux'
import { selectWebsite } from '../../redux/selectors/cms'
import Website from '../../class/models/website'
import { IPropWithData } from '../../class/interfaces/redux'

interface ISocialMediaIcon {
    data: SocialMediaLink
    color?: string
}

const SocialMediaIcon = ({ data, color }: ISocialMediaIcon) => {
    const website = useSelector<any, IPropWithData<Website>>(selectWebsite)

    const settings = website.data.settings

    let iconToUse: IconDefinition | undefined
    switch (data.type) {
        case SocialMediaTypes.FACEBOOK:
            iconToUse = faFacebookF
            break
        case SocialMediaTypes.INSTAGRAM:
            iconToUse = faInstagram
            break
        case SocialMediaTypes.TWITTER:
            iconToUse = faTwitter
            break
        case SocialMediaTypes.LINKEDIN:
            iconToUse = faLinkedin
            break
        case SocialMediaTypes.YOUTUBE:
            iconToUse = faYoutube
            break
        case SocialMediaTypes.PINTEREST:
            iconToUse = faPinterestP
            break
    }

    const iconStyles: any = {}

    if (color) {
        iconStyles.color = color
    } else if (settings && settings.primaryColor) {
        iconStyles.color = settings.primaryColor
    }

    return data ? (
        <a
            href={data.url}
            className={`${classList.link} btn btn-plain`}
            style={iconStyles}
            target="_blank"
            rel="noreferrer"
        >
            {iconToUse ? (
                <FontAwesomeIcon icon={iconToUse} />
            ) : (
                <span className="">{data.type}</span>
            )}
        </a>
    ) : null
}

export default SocialMediaIcon
