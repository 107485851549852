import React from 'react'
import Speaker from '../../../class/models/speaker'
import SpeakerGroup from '../../../class/models/speaker-group'
import CustomImage from '../../CustomImage/CustomImage'
import CustomTitle from '../../CustomTitle/CustomTitle'
import styles from '../SpeakersSection.module.scss'

interface ISpeakersSection {
    data: any
}

const SpeakersSectionTemplateOne = ({ data: { speakers, speakerGroups } }: ISpeakersSection) => {
    return speakerGroups
        ? speakerGroups.map((speakerGroup: SpeakerGroup, index: number) => (
              <div key={index}>
                  {(speakerGroup.title || speakerGroup.groupTitle) && (
                      <div className="mb-5">
                          {speakerGroup.groupTitle ? (
                              <CustomTitle data={speakerGroup.groupTitle} />
                          ) : (
                              <>{speakerGroup.title && <div>{speakerGroup.title}</div>}</>
                          )}
                      </div>
                  )}
                  {speakerGroup.speakers && (
                      <div className="row">
                          {speakerGroup.speakers.map((speaker: Speaker, index: number) => (
                              <div className="col-md" key={index}>
                                  <div className={`${styles.speaker} bg-secondary p-5 h-100`}>
                                      {speaker.image && <CustomImage data={speaker.image} />}
                                      <div className="text-center mt-3">
                                          {speaker.title && (
                                              <div>
                                                  <i>{speaker.title}</i>
                                              </div>
                                          )}
                                          <div className="font-weight-bold text-uppercase">
                                              {speaker.name}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          ))}
                      </div>
                  )}
              </div>
          ))
        : null
}

export default SpeakersSectionTemplateOne
