export default class ContentfulMediaAsset {
    title?: string
    url: string
    width: number
    height: number

    constructor(rawData: any) {
        const data = rawData || {}
        this.title = data.title
        this.url = data.url
        this.width = data.width
        this.height = data.height
    }
}
