import React from 'react'
import { useSelector } from 'react-redux'
import Image from '../../class/models/image'
import ElementAlignment from '../../enums/ElementAlignment'
import { selectIsMobile } from '../../redux/selectors/cms'
import classList from './CustomImage.module.scss'

interface ICustomImage {
    data: Image
    imageStyle?: any
}

const CustomImage = ({ data, imageStyle }: ICustomImage) => {
    const isMobile: any = useSelector(selectIsMobile)

    const styles: any = {}
    const imageStyles: any = { ...imageStyle }

    if (data.alignment === ElementAlignment.Center) {
        styles.margin = '0 auto'
        styles.textAlign = 'center'
    } else if (data.alignment === ElementAlignment.Right) {
        styles.marginLeft = 'auto'
        styles.textAlign = 'right'
    }

    if (isMobile && data.mobileAlignment) {
        if (data.mobileAlignment === ElementAlignment.Center) {
            styles.margin = '0 auto'
            styles.textAlign = 'center'
        } else if (data.mobileAlignment === ElementAlignment.Right) {
            styles.marginLeft = 'auto'
            styles.textAlign = 'right'
        }
    }

    if (data.maxWidth) {
        styles.maxWidth = `${data.maxWidth}px`
    }

    if (data.borderWidth && data.borderColor) {
        imageStyles.border = `${data.borderWidth} solid ${data.borderColor}`
    }

    if (data.width) {
        imageStyles.width = data.width
    }

    return (
        <div className={`${classList['image-wrap']} image-wrap`} style={styles}>
            <img src={data.asset.url} alt={data.asset.title} style={imageStyles} />
        </div>
    )
}

export default CustomImage
