export default class Copy {
    copy: string
    fontSize?: number
    mobileFontSize?: number;
    color?: string;
    alignment: 'left' | 'center' | 'right'
    backgroundColor?: string;

    constructor(data: any) {
        this.copy = data.copy
        this.fontSize = data.fontSize
        this.mobileFontSize = data.mobileFontSize
        this.color = data.color
        this.alignment = data.alignment ? data.alignment.toLowerCase() : 'left'
        this.backgroundColor = data.backgroundColor;
    }
}
