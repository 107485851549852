import React from 'react'
import { useSelector } from 'react-redux'
import { IPropWithData } from '../../class/interfaces/redux'
import Website from '../../class/models/website'
import { selectWebsite } from '../../redux/selectors/cms'

interface IProps {
    type?: 'button' | 'submit' | 'reset' | undefined
    children: React.ReactNode
    disabled?: boolean
    className?: string
    styles?: any
    onClick?: () => any
    secondary?: boolean
}

const Button = ({
    type = 'button',
    className,
    disabled,
    children,
    onClick,
    styles,
    secondary,
}: IProps) => {
    const website = useSelector<any, IPropWithData<Website>>(selectWebsite)

    const style: any = { ...styles, boxShadow: 'none' }

    if (website.data.settings && website.data.settings.primaryColor) {
        const colorToUse =
            secondary && website.data.settings.secondaryColor
                ? website.data.settings.secondaryColor
                : website.data.settings.primaryColor
        style.backgroundColor = colorToUse
        style.borderColor = colorToUse
    }

    return (
        <button
            type={type}
            className={`btn ${className}`}
            disabled={disabled}
            onClick={onClick}
            style={style}
        >
            {children}
        </button>
    )
}

export default Button
