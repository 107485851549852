import { createActions } from 'redux-actions'
import { takeLatest, call, all, put } from 'redux-saga/effects'
import { getWebsite } from '../../providers/cms'

import { defaultAction } from './utils'

const actions = createActions({
    CMS: {
        FETCH_WEBSITE: defaultAction,
        FETCH_EVENT_INSTANCE: defaultAction,
        SET_IS_POST_SHOW: undefined,
        SET_EVENT_STARTED: undefined,
        SET_EVENT_ENDED: undefined,
        SET_IS_MOBILE: undefined,
        CLEAR_ERRORS: undefined,
    },
})

export const {
    cms: {
        fetchWebsite: { pending: fetchWebsite },
        setIsPostShow,
        setEventStarted,
        setEventEnded,
        setIsMobile,
        clearErrors: clearErrorsCms,
    },
} = actions

function* fetchWebsiteWatcher() {
    yield takeLatest(fetchWebsite, fetchWebsiteWorker)
}

function* fetchWebsiteWorker() {
    try {
        const res = yield call(getWebsite)

        yield put(actions.cms.fetchWebsite.fulfilled(res))
    } catch (e) {
        console.error(e)
        yield put(actions.cms.fetchWebsite.rejected(e))
    }
}

export function* cmsWatcher() {
    yield all([call(fetchWebsiteWatcher)])
}
