export default class VideoEmbed {
    copy?: string
    link: string
    allow?: string
    modal: boolean
    height?: number

    constructor(data: any) {
        this.copy = data.copy || undefined
        this.link = data.link
        this.allow = data.allow || undefined
        this.modal = !!data.modal
        this.height = data.height
    }
}
