import {createActions} from 'redux-actions';
import {
    takeLatest, call, all, put,
} from 'redux-saga/effects';
import { getQuizzes, putAnswer } from '../../providers/quizzes';

import {defaultAction} from './utils';

const actions = createActions({
    QUIZZES: {
        FETCH_QUIZZES: defaultAction,
        PUT_QUIZ_ANSWER: defaultAction,
    },
});

export const {
    quizzes: {
        fetchQuizzes: {pending: fetchQuizzes},
        putQuizAnswer: {pending: putQuizAnswer},
    },
} = actions;

function* fetchQuizzesWatcher() {
    yield takeLatest(fetchQuizzes, fetchQuizzesWorker);
}

function* fetchQuizzesWorker({payload}) {
    try {
        const res = yield call(getQuizzes, payload);

        yield put(actions.quizzes.fetchQuizzes.fulfilled(res));
    } catch (e) {
        console.error(e);
        yield put(actions.quizzes.fetchQuizzes.rejected(e));
    }
}

function* putQuizzAnswerWatcher() {
    yield takeLatest(putQuizAnswer, putQuizzAnswerWorker);
}

function* putQuizzAnswerWorker({payload}) {
    try {
        const res = yield call(putAnswer, payload);

        yield put(actions.quizzes.putQuizAnswer.fulfilled(res));
    } catch (e) {
        console.error(e);
        yield put(actions.quizzes.putQuizAnswer.rejected(e));
    }
}

export function* quizzesWatcher() {
    yield all([
        call(fetchQuizzesWatcher),
        call(putQuizzAnswerWatcher),
    ]);
}
