import amplitude, { AmplitudeClient } from 'amplitude-js';
import { getSubdomain } from '../services/utils';
import Analytics from './analytics';
import Contentful from './contentful';

class AmplitudeProvider {
    private amplitudeClient: AmplitudeClient = null as any;
    private initialized: boolean = false;
    private amplitudeId: string = '';
    isInit: Function = () => { return !!this.initialized };

    constructor() {
        this.init();
    }

    private init = () => {
        Contentful.getWebsiteSettings(getSubdomain()).then((website) => {
            const { settings } = website ?? {};

            if (!settings) {
                console.warn('Failed to get Website Settings')
                Analytics.track(`Amplitude API Init Failed`, { 'message': 'no settings found' });
                return;
            }

            const { amplitudeId } = settings;

            if (!amplitudeId) {
                console.warn('No amplitudeId set')
                Analytics.track(`Amplitude API Init Failed`, { 'message': 'No amplitudeId set' });
                return;
            }
            this.amplitudeClient = amplitude.getInstance();
            this.amplitudeClient.init(amplitudeId);
            this.setAmplitudeId(amplitudeId);
            this.setInitialized(true);
        });
    }

    identify = (userID: string, properties: any) => {
        if (!this.isInit()) return;
        
        this.amplitudeClient.setUserId(userID);
        this.amplitudeClient.setUserProperties(properties);
        
        this.logEvent('User Identified', { ...properties })
    }

    logEvent = (eventType: string, properties: any) => {
        if (!this.isInit()) return;

        this.amplitudeClient.logEvent(eventType, { ...properties });
    }

    private setInitialized = (init: boolean) => { this.initialized = init };

    private setAmplitudeId = (amplitudeId: string) => { this.amplitudeId = amplitudeId };

}

const Amplitude = new AmplitudeProvider();


export default Amplitude;