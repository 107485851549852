import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { IPropWithData } from '../../../class/interfaces/redux'
import Website from '../../../class/models/website'
import { selectWebsite } from '../../../redux/selectors/cms'
import UserService from '../../../services/user'
import classList from '../IFrameChat/IFrameChat.module.scss'

interface IStateProps {
    websiteData: IPropWithData<Website>
}

const mapStateToProps = (state: any): IStateProps => {
    return {
        websiteData: selectWebsite(state),
    }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type IProps = PropsFromRedux

class MinnitChat extends React.Component<IProps> {
    render() {
        const {
            websiteData: {
                data: { videoChat },
            },
        } = this.props

        const username = UserService.getUsername() || ''
        const urlEncodedUsername = encodeURIComponent(username)

        if (!videoChat || !videoChat.minnitChatId) {
            return null
        }

        return (
            <div className={classList['iframe-wrapper']}>
                <iframe
                    src={`https://minnit.chat/${videoChat.minnitChatId}?embed&nickname=${urlEncodedUsername}`}
                    className={classList['iframe']}
                    title="Chat"
                ></iframe>
            </div>
        )
    }
}

const IFrameChat = connect(mapStateToProps)(MinnitChat)
export default IFrameChat
