enum ComponentsList {
    CustomTitle = 'Title',
    CustomCopy = 'Copy',
    CustomImage = 'Image',
    CustomImageGroup = 'ImageGroup',
    CustomLink = 'Link',
    CustomContent = 'CustomContent',
    Sponsors = 'Sponsors',
    SpeakersSection = 'GuestSpeakersSection',
    PrizeSection = 'PrizeSection',
    VideoChatSection = 'VideochatSection',
    LinkGroup = 'LinkGroup',
    VideoEmbed = 'VideoEmbed'
}

export default ComponentsList
