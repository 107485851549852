import Sponsor from './sponsor'
import Title from './title'

export default class SponsorGroup {
    title?: string
    groupTitle?: Title
    featuredGroup: boolean
    sponsors: Sponsor[]

    constructor(data: any) {
        this.title = data.title
        this.groupTitle = data.groupTitle ? new Title(data.groupTitle) : undefined
        this.featuredGroup = !!data.featuredGroup
        this.sponsors =
            data.sponsorsCollection && data.sponsorsCollection.items
                ? data.sponsorsCollection.items.map((sponsor: any) => new Sponsor(sponsor))
                : []
    }
}
