import Prize from './prize'

export default class PrizeSection {
    template: string
    prizes: Prize[]

    constructor(data: any, prizes?: any) {
        this.template = data.template
        this.prizes = prizes ? prizes.map((prize: any) => new Prize(prize)) : []
    }
}
